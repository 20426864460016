import React from 'react';
import { get, post, put, del, patch } from '../../../../../Utility/Http';
import { personalDetailsUpdateApi, userPersonalInfoGetApi } from '../Model/PersonalModel';


export const userPersonalInfoGet = (id) => {
    return get(`${process.env.EXTENDED_URL}user_personal_info/${id}`).then((response) => {
        return userPersonalInfoGetApi(response)
    });
};

export const personalDetailsUpdate = (data, type, id,) => {
    return patch(`${process.env.EXTENDED_URL}user_personal_info/${id}`, data).then((response) => {
        return personalDetailsUpdateApi(response)
    });
};