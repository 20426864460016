import React, { Component, lazy, Suspense } from 'react';
import { createHashHistory } from 'history';
import { Routes, Route, Switch, Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginRoute from "./LoginRoute";
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import NotFound from "../../../Utility/Pages/NotFound";
LoadingOverlay.propTypes = undefined;
class LoginIndex extends Component {

    componentDidMount() {
        // Perform side effects after the render is committed to the DOM
        if (this.props.token == "" || this.props.token == null) {
            this.props.history.push('/');
        }
    }
    render() {
        return (
            <Switch>
                <Route path="/" component={LoginRoute} />
                <Route path="/:lng/login" component={LoginRoute} />
                <Route exact path="/:lng/forgot_password" component={LoginRoute} />
                <Route exact path="/:lng/payment/:hss?/:pss?/:sss?" component={LoginRoute} />
                <Route exact path="/:lng/home/:type?/:branchId?" component={LoginRoute} />
            </Switch>


        );
    }
}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        accessToken: globalState.LoginReducer.accessToken,
        token: globalState.LoginReducer.token,
        leftbar: globalState.mainReducerData.leftbar,
    };
}

export default connect(mapStateToProps)(withRouter(LoginIndex));


