import React, { Component, lazy, Suspense } from 'react';
import { createHashHistory } from 'history';
import { Routes, Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../../Utility/Pages/NotFound";
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SettingsRouteAgent = lazy(() => import('../Modules/Home/Components/Agent/Components/SettingsForAgent/Routes/SettingsRouteAgent'));
const DashboardRoute = lazy(() => import('../Modules/Dashboard/Routes/DashboardRoute'));
const HomeRoute = lazy(() => import('../Modules/Home/Routes/HomeRoute'));
const AgentRoute = lazy(() => import('../Modules/Home/Components/Agent/Routes/AgentRoute'));
const SettingsRoute = lazy(() => import('../Modules/Settings/Routes/SettingsRoute'));

LoadingOverlay.propTypes = undefined;
class index extends Component {
  componentDidMount() {
    if (this.props.token && this.props.token != "") {
      if (this.props.history.location.pathname.split('/')[2] == "login") {
        this.props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
      }
    }
  }
  render() {
    return (
      <>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={DashboardRoute} />
            <Route exact path="/:lng/dashboard" component={DashboardRoute} />
            <Route exact path="/:lng/home/:type?/:branchId?" component={HomeRoute} />
            <Route exact path="/:lng/booking_confirmation/:bookingID?" component={HomeRoute} />
            <Route exact path="/:lng/payment" component={HomeRoute} />
            <Route exact path="/:lng/settings/:type?/:next_step?" component={SettingsRoute} />
            <Route exact path="/:lng/agent_dashboard" component={AgentRoute} />
            <Route exact path="/:lng/agent/:type?/:branchId?" component={AgentRoute} />
            <Route exact path="/:lng/agent_settings/:type?/:next_step?" component={SettingsRouteAgent} />
            <Route exact path="/:lng/agent/agent_booking" component={AgentRoute} />
          </Switch >
        </Suspense >
      </>


    );
  }
}

const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    accessToken: globalState.LoginReducer.accessToken,
    token: globalState.LoginReducer.token,
    leftbar: globalState.mainReducerData.leftbar,
  };
}

export default connect(mapStateToProps)(withRouter(index));

function Loading() {
  return (
    <>
      <div className="wrapper">
        <span>Loading</span>
      </div>
    </>
  );
}
