import React from 'react';
import { get, post, put, del, patch } from '../../../../../../Utility/Http';
import { hotelBranchListGet, branchStatusUpdateApi, blockRoomListGet, hotelRoomBookingsPostCall, branchCreatePost, branchCreatePatch, branchDeleteApi, hotel_room_list_get, blockRoomPost, roomRateOverridePost, specialRatePost, subscriptionTypeGet, subscriptionDetailsPost, paymentPriceDistributionGet, paymentPost, hotle_NotLiveBranch_listGet, hotle_wise_RoomType_listGet, roomType_wise_room_listGet, roomRateOverrideMonthlyCalculations_get, hotelRoomTypesSearchListGet, hotelBranchLocationGet, branchImageSavePost, customerListApiGet, paymentDetailsApiGet, guestCreateApi, locationWiseBranchApiGet, countryListGet, stateListGet, cityListGet, emailCheckApiGet } from '../Model/HotelBranchModel';

import * as Store from '../../../../../../store'
let version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";


export const hotelBranchList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_branch`, data);
    return hotelBranchListGet(response);
};
export const hotle_NotLiveBranch_list = async () => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/room_rate_override`, null);
    return hotle_NotLiveBranch_listGet(response);
};
export const hotle_wise_RoomType_list = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/room_rate_override`, data);
    return hotle_wise_RoomType_listGet(response);
};
export const roomType_wise_room_list = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room`, data);
    return roomType_wise_room_listGet(response);
};

export const blockRoomList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room`, data);
    return blockRoomListGet(response);
};

export const hotelRoomBookings = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}${version}/bookings`, data);
    return hotelRoomBookingsPostCall(response);
};
export const branchCreate = async (data, id, type) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    if (type == 'post') {
        const response = await post(`${process.env.EXTENDED_URL}${version}/hotel_branch`, data);
        return branchCreatePost(response);
    } else {
        const response_1 = await patch(`${process.env.EXTENDED_URL}${version}/hotel_branch/${id}`, data);
        return branchCreatePatch(response_1);
    }
};
export const branchDelete = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await del(`${process.env.EXTENDED_URL}${version}/hotel_branch`, data);
    return branchDeleteApi(response);
};

export const branchStatusUpdate = async (id, data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await patch(`${process.env.EXTENDED_URL}${version}/hotel_branch/${id}`, data);
    return branchStatusUpdateApi(response);
};

export const hotel_room_list = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room`, data);
    return hotel_room_list_get(response);
};

export const roomRateOverrideCreate = async (data, type) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    if (type == 'room_rate_override') {
        const response = await post(`${process.env.EXTENDED_URL}${version}/room_rate_override`, data);
        return roomRateOverridePost(response);
    } else {
        const response_1 = await post(`${process.env.EXTENDED_URL}${version}/special_rate`, data);
        return specialRatePost(response_1);
    }
};
export const blockRoom = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}${version}/room/block`, data);
    return blockRoomPost(response);
}

export const subscriptionDetails = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}subscription_details`, data);
    return subscriptionDetailsPost(response);
}

export const subscriptionTypeList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/subscription_type`, data);
    return subscriptionTypeGet(response);
};

// export const getCurrentUser = async () => {
//     const response = await get(`${process.env.EXTENDED_URL}currentuser`);
//     return currentUser(response);
// };

export const paymentPriceDistribution = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}${version}/bookings/price_distribution`, data);
    return paymentPriceDistributionGet(response);
};


export const payment = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}${version}${version}//payment`, data);
    return paymentPost(response);
}

export const roomRateOverrideMonthlyCalculations = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/room_rate_override/monthly_calculation`, data);
    return roomRateOverrideMonthlyCalculations_get(response);
};
export const hotelRoomTypesSearchList = async (data, type) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    if (type == "search") {
        const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room_types/search`, data);
        return hotelRoomTypesSearchListGet(response);
    } else {
        const response_1 = await get(`${process.env.EXTENDED_URL}${version}/hotel_room_types/advance_search`, data);
        return hotelRoomTypesSearchListGet(response_1);
    }
};
export const hotelBranchLocation = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_branch/location`, data);
    return hotelBranchLocationGet(response);
};
export const branchImageSave = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await post(`${process.env.EXTENDED_URL}${version}/hotel_branch/upload_image`, data);
    return branchImageSavePost(response);
};

export const customerListApi = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}admin`, data);
    return customerListApiGet(response);
};

export const paymentDetailsApi = async (id) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/onpay/online_payment_detail/${id}`, null);
    return paymentDetailsApiGet(response);
};

export const guestCreate = async (data, headers) => {
    const response = await post(`${process.env.EXTENDED_URL}admin/create`, data, headers);
    return guestCreateApi(response);
};
export const locationWiseBranchApi = async (id) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room_types/get_branch_by_location/${id}`, null);
    return locationWiseBranchApiGet(response);
};

export const getCountryList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/location/country`, data);
    return countryListGet(response);
};

export const getStateList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/location/state`, data);
    return stateListGet(response);
};
export const getCityList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/location/city`, data);
    return cityListGet(response);
};
export const emailCheckApi = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}admin/check_email`, data);
    return emailCheckApiGet(response);
};

