import React, { useState, useEffect } from 'react';
import { Routes, Route, HashRouter, Switch, useHistory, useNavigate } from "react-router-dom";
import { loaderStateTrue, loaderStateFalse } from '../src/Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from './GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import Popup from 'react-popup';
import LoginIndex from './GlobalModules/Login/Routes/LoginIndex'
import DemoAggrid from './GlobalModules/Login/Pages/DemoAggrid'
import 'dotenv/config'
import log from 'loglevel';
import V1Routes from '../src/V1/Routes/index';
import V2Routes from '../src/V2/Routes/index';
import V3Routes from '../src/V3/Routes/index';
import V4Routes from '../src/V4/Routes/index';
import MfaRoutes from '../src/V4/Routes/MfaRoutes';
import GlobalRoutes from './GlobalModules/Routes/GlobalRoute';
import logger from './logger';
import { withNamespaces } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import { checkLiveOrNot } from './Utility/Controller/UtilityController';
import ComingSoonPage from './Utility/Pages/ComingSoonPage';
import Default from "../src/Layout/Default";
import Utility from './Utility/Utility';
import ErrorBoundary from './Utility/Components/ErrorBoundary';
import ModalGlobal from './Utility/Components/ModalGlobal';
import SmsVerification from './GlobalModules/Login/Components/SmsVerification';
import { getCurrentUser, verifySoftwareToken, mfaAssign } from './GlobalModules/Login/Controller/LoginController';
import EventEmitter from './Utility/Components/EventEmitter';
LoadingOverlay.propTypes = undefined;

const channel = new BroadcastChannel('foobar');

function App(props) {
  const { loaderStateFalse, isLoading, token, userCredentials } = props;
  const [live, setLive] = useState(null);
  const [liveApiSuccess, setLiveApiSuccess] = useState(false);
  const [mfaModalFlag, setMfaModalFlag] = useState(false);
  const history = useHistory();

  //intially fetch project is live or not
  useEffect(() => {
    async function fetchData() {
      try {
        let data = {};
        data['type'] = "admin";
        let filters = {}
        filters['filters'] = JSON.stringify(data);
        const response = await checkLiveOrNot(filters);
        if (response?.success && response.data.length > 0) {
          setLive(response.data[0].is_live);
        }
        setLiveApiSuccess(true);
      } catch (error) {
        Utility.toastNotifications(error?.message, 'Error', 'error');
      }
    }
    fetchData();
  }, []);

  // useEffect hook to perform side effects based on the 'live' state variable
  useEffect(() => {
    if (live === 1) {
      // If so, call the channelMessage function to set up the message listener
      channelMessage();
    }
  }, [live]);

  // Function to set up the message listener on the channel
  const channelMessage = () => {
    // Assign a callback function to handle incoming messages from the channel
    channel.onmessage = (event) => {
      if (event.data.isLogout) {
        window.location.href = window.location.origin + "/";
      }
    };
    loaderStateFalse();
  }

  //after fetch user details check user is mandatory mfa or not
  useEffect(() => {
    if (Object.keys(props.userCredentials).length > 0 && props.userCredentials.user_details && Object.keys(props.userCredentials.user_details).length > 0 && props.userCredentials.user_details.hasOwnProperty('mfa') && props.userCredentials.user_details.mfa == null && props.userCredentials.user_details.user_type !== 'app_admin') {
      if (props.userGroup && props.userGroup.length > 0) {
        //check from all organisation , mfa is mandatory or not
        let userMfaEnableFlag = props.userGroup.some(user => user.is_mfa_required === true);
        if (userMfaEnableFlag) {
          setMfaModalFlag(true)
        } else {
          setMfaModalFlag(false)
        }
      }
    } else {

    }
  }, [props.userCredentials])

  //close mfa modal fn
  const closeMfaModalFn = () => {
    setMfaModalFlag(false)
  }

  //mfa otp submit fn callback
  const handleSubmitOtpVerfication = (otp, type) => {
    const { loaderStateTrue, loaderStateFalse } = props;
    if (type === 'authenticator') {
      loaderStateTrue();
      let data = {
        "mfaCode": otp.join(''),
      }
      verifySoftwareToken(data).then((response) => {
        if (response.success) {
          const mfaAssignData = {
            "mfa_type": "authenticator_app",
            "user_name": props.userCredentials?.user_details?.user_email
          }
          mfaAssign(mfaAssignData).then((response) => {
            if (response.success) {
              setMfaModalFlag(false);
              getCurrentUserFn();
            } else {
              Utility.toastNotifications(response.message, 'Error', 'error');
            }
            loaderStateFalse();
          }).catch((error) => {
            loaderStateFalse();
          });
        } else {
          Utility.toastNotifications(response.message, "Error", "error")
        }
        loaderStateFalse();
      }).catch((error) => {
        console.error("************error*************", error)
        if (error) {
          Utility.toastNotifications(error?.response?.message, "Warning", "warning")
        }
        loaderStateFalse();
      });
    }
  }

  //fetch current user data
  const getCurrentUserFn = () => {
    const { loaderStateTrue, loaderStateFalse, setUserCredentials, loginCredentials } = props;
    getCurrentUser().then((response) => {
      if (response.success) {
        setUserCredentials(response.data);
        //for routing , emit the data to mfa page 
        EventEmitter.emit("sendMessage", "route_permission")
      }
    }).catch((error) => {
      console.error("************error*************", error)
      loaderStateFalse();
    });
  }

  function Loading() {
    return (
      <>
        <div className="wrapper">
          <span>Loading</span>
        </div>
      </>
    );
  }



  return (
    <>
      {liveApiSuccess && (
        <LoadingOverlay
          active={isLoading}
          className='lodingOverlayDiv'
          spinner={<FadeLoader color={'#ccc !important'} size={50} margin='0px' speedMultiplier={0.3} />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.1)',
              zIndex: '9999999999999'
            })
          }}
        >
          <Popup />
          <HashRouter>
            {live != null ? (
              <>
                {live === 1 ? (
                  <>
                    {Object.keys(props.userCredentials).length > 0 && token !== "" && (!props.userCredentials?.user_details?.version || props.userCredentials?.user_details?.version === '') ? (
                      <GlobalRoutes />
                    ) : (
                      <>
                        {props.userCredentials?.user_details?.version === 'v1' && token !== "" ? (
                          <V1Routes />
                        ) : props.userCredentials?.user_details?.version === 'v2' && token !== "" ? (
                          <V2Routes />
                        ) : props.userCredentials?.user_details?.version === 'v3' && token !== "" ? (
                          <V3Routes />

                        ) : props.userCredentials?.user_details?.version === 'v4' && token !== "" && props.mfa == null && props.userGroup.some(user => user.is_mfa_required === true) ? (
                          <MfaRoutes />
                        )

                          : props.userCredentials?.user_details?.version === 'v4' && token !== "" ? (
                            <V4Routes />
                          ) : (
                            <LoginIndex />
                          )

                        }

                      </>
                    )}
                    {/* mfa modal */}
                    <ErrorBoundary title="ModalGlobal Error">
                      <ModalGlobal
                        show={mfaModalFlag}
                        onHide={closeMfaModalFn}
                        title=""
                        className="modalcustomize mondimension smsVerificationModalContent userprofileSmsVerificationModal"
                        footer={false}
                        headerShow={false}
                        closeButton={true}
                        saveButtonLabel="Confirm"
                        saveButtonClassName="btn btn-primary savebtn"
                        cancelShow={true}
                        body={
                          <>
                            <ErrorBoundary title="GlobalAddEditModalContent Error">
                              <SmsVerification
                                formData={{ 'email': props.userCredentials?.user_details?.user_email }}
                                callFrom={''}
                                handleSubmitOtpVerfication={handleSubmitOtpVerfication}
                                enableMfa={false}
                                closeMfaModalFn={closeMfaModalFn}
                                disableAction={true}
                                landingPage={true}
                              />
                            </ErrorBoundary>
                          </>
                        }
                      />
                    </ErrorBoundary>
                  </>
                ) : (
                  <Switch>
                    <Route
                      render={() => (
                        <Default>
                          <ComingSoonPage />
                        </Default>
                      )}
                    />
                  </Switch>
                )}
              </>
            ) : (
              <Switch>
                <Route
                  render={() => (
                    <Default>
                      <ComingSoonPage />
                    </Default>
                  )}
                />
              </Switch>
            )}
          </HashRouter>
        </LoadingOverlay>
      )}
      {!liveApiSuccess && <Loading />

      }
    </>
  );
}

const mapStateToProps = (globalState) => ({
  isLoading: globalState.mainReducerData.loaderState,
  token: globalState.LoginReducer.token,
  userCredentials: globalState.LoginReducer.userCredentials,
  userGroup: globalState.LoginReducer?.userGroup,
  mfa: globalState?.LoginReducer?.userCredentials?.user_details?.mfa,
});

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withNamespaces()(App));
