import React, { Component } from 'react';
import '../Public/css/footer.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Accordion } from "react-bootstrap";
import { handleActiveLink, handleLeft } from '../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp, userrole } from '../../GlobalModules/Login/Actions/LoginAction';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <>
                <div className="footer-inner">
                    <div className="footer-inner-content noMarketed">
                        <div className="content-box">
                            <span className="content-label">The Staying ©2024 All rights reserved.</span>
                        </div>
                        <div className="content-box content-box-end">
                            <div className='marketed_by_box'>
                                <span className="content-label">Design & developed by - <a href='https://mettletech.co/' target='_blank' className='goMTSite'>Mettle Tech</a></span>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}


function mapStateToProps(globalState) {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,

    };
}

export default connect(mapStateToProps, { handleActiveLink, handleLeft, setToken })(Footer);