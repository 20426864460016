import React from 'react';
import { get, post, put, del, patch } from '../../../../../Utility/Http';
import { updateProfessionalDetailsUpdateApi, userProfessionalInfoGetApi } from '../Model/ProfessionalModel';

export const userProfessionalInfoGet = (id) => {
    return get(`${process.env.EXTENDED_URL}user_professional_info/${id}`).then((response) => {
        return userProfessionalInfoGetApi(response)
    });
};


export const updateProfessionalDetailsUpdate = (data, type, id,) => {
    return patch(`${process.env.EXTENDED_URL}user_professional_info/${id}`, data).then((response) => {
        return updateProfessionalDetailsUpdateApi(response)
    });
};