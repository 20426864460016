import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { withNamespaces } from 'react-i18next';

class ImageCropContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { crop, croppedImageUrl, src, circularCrop } = this.props;
        return (
            <div className="modalinnerbody">
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        circularCrop={circularCrop}
                        onImageLoaded={this.props.onImageLoaded}
                        onComplete={this.props.onCropComplete}
                        onChange={this.props.onCropChange}
                    />
                )}
            </div>
        );
    }
}


ImageCropContent.defaultProps = {
    circularCrop: true
}

export default withNamespaces()(ImageCropContent);
