import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../GlobalModules/Login/Actions/LoginAction';
import { withRouter } from 'react-router-dom';
import '../Public/css/bookingConfirm.scss';
import { paymentDetailsApi, createPaymentApi } from '../../V1/Modules/Home/Components/HotelBranch/Controller/HotelBranchController'

class PaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentToken: "",
            isTokenExpired: false,
            paymentDetailsResponseData: {},
            formData: { "name": "", "card_number": "" },
            errorFormData: { "name": "", "card_number": "" }
        }
        this.paymentTokenRef = React.createRef();
    }

    componentDidMount() {
        this.updatePaymentToken();
    }

    updatePaymentToken() {
        const newPaymentTokenHss = this.props.match.params.hss;
        const newPaymentTokenPss = this.props.match.params.pss;
        const newPaymentTokenSss = this.props.match.params.sss;

        const newPaymentToken = newPaymentTokenHss + '.' + newPaymentTokenPss + '.' + newPaymentTokenSss;
        if (this.paymentTokenRef.current !== newPaymentToken) {
            this.setState({
                paymentToken: newPaymentToken
            }, () => {
                this.verifyToken();
                this.paymentTokenRef.current = newPaymentToken;
            });
        }
    }

    verifyToken = () => {
        const { paymentToken } = this.state;
        try {
            const tokenParts = paymentToken.split('.');
            const payloadBase64 = tokenParts[1];
            const decodedPayload = JSON.parse(atob(payloadBase64));
            const isTokenExpired = decodedPayload.exp && decodedPayload.exp * 1000 < Date.now();
            if (isTokenExpired) {
                this.setState({
                    isTokenExpired: true
                })
            } else {
                let tempDecodedPayload = { ...decodedPayload }
                if (tempDecodedPayload.hasOwnProperty('pay_request_id') && tempDecodedPayload.pay_request_id != null) {
                    let id = tempDecodedPayload.pay_request_id;
                    this.getPaymentDetailsApiFn(id);
                }
            }

        } catch (error) {
        }
    };

    getPaymentDetailsApiFn = (id) => {
        const promise = new Promise((resolve, reject) => {
            const { loaderStateTrue, loaderStateFalse } = this.props;
            loaderStateTrue();
            paymentDetailsApi(id).then((response) => {
                if (response.success) {
                    this.setState({
                        paymentDetailsResponseData: response.data
                    })
                    if (response.data.hasOwnProperty('is_paid') && response.data.is_paid != null && response.data.is_paid == true) {
                        this.setState({
                            isTokenExpired: true
                        })
                    }
                }
                loaderStateFalse();
            }).catch((error) => {
                console.error("************error*************", error)
                if (error) {
                }
                loaderStateFalse();
                if (error.message == "Network Error") {
                }
            });
        })
        return promise;
    }

    getCurrencySymbol = (currencyCode) => {
        try {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
            });

            // Extracting the currency symbol from the formatted number
            const parts = formatter.formatToParts(1);
            const symbol = parts.find(part => part.type === 'currency')?.value;

            return symbol || null;
        } catch (error) {
            //console.error('Error getting currency symbol:', error);
            return null;
        }
    }


    handleChange = (e, type) => {

        let tempFormData = { ...this.state.formData };
        let tempErrorFormData = { ...this.state.errorFormData }

        if (type == "name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['name'] = ""
                    tempFormData["name"] = ""
                } else {
                    tempFormData["name"] = e.target.value
                    tempErrorFormData['name'] = ""
                }
            }
        }
        if (type == "card_number") {
            var expr = /^[0-9]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempErrorFormData['card_number'] = ""
                    tempFormData["card_number"] = ""
                } else {
                    tempFormData["card_number"] = e.target.value
                    tempErrorFormData['card_number'] = ""
                }
            }
        }


        this.setState({
            formData: tempFormData,
            errorFormData: tempErrorFormData
        })
    }

    handleSubmit = (event) => {
    }




    render() {
        return (
            <div className="paymentContainer">
                <div id="Checkout" className="inline">
                    <h1>Pay Invoice</h1>
                    {this.state.isTokenExpired ? <h6 style={{ color: 'red' }}>The link has been expired .please contact with the adminstrator.</h6> : ""}
                    <div className="card-row">
                        <span className="visa"></span>
                        <span className="mastercard"></span>
                        <span className="amex"></span>
                        <span className="discover"></span>
                    </div>
                    <form>
                        <div className="form-group">
                            <label for="PaymentAmount">Payment amount</label>
                            <div className="amount-placeholder">
                                <span>{this.state.paymentDetailsResponseData.hasOwnProperty('currency') ? this.getCurrencySymbol(this.state.paymentDetailsResponseData.currency) + " " : ' '}</span>
                                <span>{this.state.paymentDetailsResponseData.hasOwnProperty('amount') ? this.state.paymentDetailsResponseData.amount : '0'}</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label or="NameOnCard">Name on card</label>
                            <input id="NameOnCard" className="form-control" type="text" maxlength="255" value={this.state.formData.name} onChange={(e) => this.handleChange(e, 'name')}></input>
                        </div>
                        <div className="form-group">
                            <label for="CreditCardNumber">Card number</label>
                            <input id="CreditCardNumber" className="null card-image form-control" type="text" value={this.state.formData.card_number} onChange={(e) => this.handleChange(e, 'card_number')}></input>
                        </div>
                        <div className="expiry-date-group form-group">
                            <label for="ExpiryDate">Expiry date</label>
                            <input id="ExpiryDate" className="form-control" type="text" placeholder="MM / YY" maxlength="7"></input>
                        </div>
                        <div className="security-code-group form-group">
                            <label for="SecurityCode">Security code</label>
                            <div className="input-container" >
                                <input id="SecurityCode" className="form-control" type="text" ></input>
                                <i id="cvc" className="fa fa-question-circle"></i>
                            </div>
                        </div>
                        <div className="zip-code-group form-group">
                            <label for="ZIPCode">ZIP/Postal code</label>
                            <div className="input-container">
                                <input id="ZIPCode" className="form-control" type="text" maxlength="10"></input>
                                <a tabindex="0" role="button" data-toggle="popover" data-trigger="focus" data-placement="left" data-content="Enter the ZIP/Postal code for your credit card billing address."><i className="fa fa-question-circle"></i></a>
                            </div>
                        </div>
                        <button id="PayButton" className="btn btn-block btn-success submit-button submit-button-full" onClick={this.handleSubmit}>
                            <span className="submit-button-lock"></span>
                            <span className="align-middle">Pay $500.00</span>
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}




const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, handleActiveLink, setUserCredentials })(withRouter(PaymentPage));
