import React, { Component, useEffect, lazy, Suspense } from 'react';
import Default from "../../../Layout/Default";
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import AppLandingPage from '../Pages/AppLandingPage';
import LoginPage from '../Pages/LoginPage';
import ForgotPassword from '../Pages/ForgotPassword';
import PaymentPage from '../../../Utility/Components/PaymentPage';
import logger from '../../../logger';
import * as Store from '../../../store'
import Auth from '../../../Layout/Auth';
import LoadingOverlay from 'react-loading-overlay';
const version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? (Store.default.getState().LoginReducer.userCredentials.user_details.version).toUpperCase() : "V2";
const HomePage = React.lazy(() => import(`../../../${version}/Modules/Home/Pages/HomePage`));
const SettingsPage = React.lazy(() => import(`../../../${version}/Modules/Settings/Pages/SettingsPage`));


function LoginRoute() {
    const history = useHistory();

    return (
        <Switch>
            {/* app landing page route */}
            <Route
                exact
                path="/"
                render={() => (
                    <Default history={history}>
                        <AppLandingPage history={history} />
                    </Default>
                )}
            />

            {/* login route */}
            <Route
                exact
                path="/:lng/login"
                render={() => (
                    <Default history={history}>
                        <LoginPage history={history} />
                    </Default>
                )}
            />

            {/* forget password route */}
            <Route
                exact
                path="/:lng/forgot_password/:type?/"
                render={() => (
                    <Default history={history}>
                        <ForgotPassword history={history} />
                    </Default>
                )}
            />
            <Route
                exact
                path="/:lng/payment/:hss?/:pss?/:sss?"
                render={() => (
                    <Default history={history}>
                        <PaymentPage history={history} />
                    </Default>
                )}
            />

            {/* home module route */}
            <Route
                exact
                path="/:lng/home/:type?/:branchId?"
                render={() => (
                    <Auth history={history} allowedRoles={['app_admin', 'admin', 'user', 'staff']}>
                        <Suspense fallback={<Loading />}>
                            <HomePage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

            {/* setting module route */}
            <Route
                exact
                path="/:lng/settings/:type?/:next_step?"
                render={() => (
                    <Auth history={history} allowedRoles={['app_admin', 'admin', 'user', 'staff']}>
                        <Suspense fallback={<Loading />}>
                            <SettingsPage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

        </Switch>
    );

}

function Loading() {
    return (
        <>
            <div className="wrapper">
                <LoadingOverlay
                    active={true}
                    className='lodingOverlayDiv'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(255, 255, 255, 0.1)',
                            zIndex: '9999999999999'
                        })
                    }}
                ></LoadingOverlay>
            </div>
        </>
    );
}


export default LoginRoute;
