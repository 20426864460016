import React, { Component } from 'react';
import CustomInput from '../Components/CustomInput';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setToken, logOutApp } from '../../GlobalModules/Login/Actions/LoginAction';
import { loaderStateTrue, loaderStateFalse } from '../../Actions/AllAction';
import * as UtilityController from '../Controller/UtilityController';
import Utility from '../Utility'

import { withNamespaces } from 'react-i18next';

class ChangePasswordContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: "",
			password: '',
			newPassword: "",
			confirmPassword: "",
			userEmailError: "",
			passwordError: "",
			newPasswordError: "",
			confirmPasswordError: "",
			oldPasswordLock: false,
			oldPasstype: "password",
			newPasswordLock: false,
			newPasstype: "password",
			newConfrimPasswordLock: false,
			newConfrimPasstype: "password",
		}
	}

	//password, confirm Password and new password onchange fn
	handleChangeUserData = (event, type) => {
		if (type == "password") {
			if (event != "") {
				this.setState({
					password: event.target.value,
					passwordError: "",
				})
			} else {
				this.setState({
					password: event.target.value,
					passwordError: "Required field"
				})
			}
		}
		if (type == "newPassword") {
			if (event != "") {
				this.setState({
					newPassword: event.target.value,
					newPasswordError: "",
				})
			} else {
				this.setState({
					newPassword: event.target.value,
					newPasswordError: "Required field"
				})
			}
		}
		if (type == "confirmPassword") {
			if (event != "") {
				this.setState({
					confirmPassword: event.target.value,
					confirmPasswordError: "",
				})
			} else {
				this.setState({
					confirmPassword: event.target.value,
					confirmPasswordError: "Required field"
				})
			}
		}
		this.setState({
		}, () => {
		})
	}

	//password change modal valdation
	validchangePasswordForm = () => {
		let valid = true;
		if (this.state.password.trim() == "") {
			valid = false;
			this.setState({
				passwordError: this.props.t('invalidField')
			})
		}
		if (this.state.newPassword.trim() == "") {
			valid = false;
			this.setState({
				newPasswordError: this.props.t('invalidField')
			})
		}
		if (this.state.confirmPassword.trim() == "") {
			valid = false;
			this.setState({
				confirmPasswordError: this.props.t('invalidField')
			})
		}

		if (this.state.newPassword.trim() != "" && this.state.confirmPassword.trim() != "") {
			if (this.state.newPassword.trim() == this.state.confirmPassword.trim() && this.state.newPassword.trim() != "" && this.state.confirmPassword.trim() != "") {
				this.setState({
					newPasswordError: '',
					confirmPasswordError: ''
				})
			} else {
				valid = false;
				Utility.toastNotifications(this.props.t('passwordAndConfirmPasswordNotMatch'), "Error", "error");
			}
		}
		return valid;
	}

	//change password submit fn
	changePasswordfromSubmitData = () => {
		let valid = this.validchangePasswordForm();
		if (valid) {
			const { loaderStateTrue, loaderStateFalse } = this.props;
			let data = {
				"pre_password": this.state.password.trim(),
				"new_password": this.state.newPassword.trim()
			};
			loaderStateTrue();
			UtilityController.userNewPasswordChange(data).then((response) => {
				loaderStateFalse();
				if (response.success) {
					Utility.toastNotifications(response.message, "Success", "success");
					this.props.closeChangePassword();
				}

			}).catch((error) => {
				console.error("************error*************", error)
				if (error) {
				}
				loaderStateFalse();
				if (error.message == "Network Error") {
				}
			});
		}
	}

	//password show eye icon
	showTextOldPassword = () => {
		this.setState({
			oldPasswordLock: true,
			oldPasstype: "text"
		})
	}

	//password hide eye icon
	showOldPassword = () => {
		this.setState({
			oldPasswordLock: false,
			oldPasstype: "password"
		})
	}

	//new password show eye icon
	showTextNewPassword = () => {
		this.setState({
			newPasswordLock: true,
			newPasstype: "text"
		})
	}

	// new password hide eye icon
	showNewPassword = () => {
		this.setState({
			newPasswordLock: false,
			newPasstype: "password"
		})
	}

	//confirm password show eye icon
	showTextNewConfrimPassword = () => {
		this.setState({
			newConfrimPasswordLock: true,
			newConfrimPasstype: "text"
		})
	}

	//confirm password hide eye icon
	showNewConfrimPassword = () => {
		this.setState({
			newConfrimPasswordLock: false,
			newConfrimPasstype: "password"
		})
	}


	render() {
		const { closeChangePassword, userCredentials } = this.props;
		let user_email = "";
		if (userCredentials && userCredentials.user_details && userCredentials.user_details.user_email != "") {
			user_email = userCredentials.user_details.user_email
		}

		if (user_email == "" && userCredentials && userCredentials.user_details && userCredentials.user_details.contact_number != "") {
			user_email = userCredentials.user_details.contact_number
		}

		return (
			<>
				<div className="modalinnerbody">
					<div className="change_password_row_body password-rules-box password-rules-change">
						<div className='pass-left-box'>
							<div className="change_password_row">
								<CustomInput
									parentClassName="comment_input_field"
									name="userEmail"
									type="text"
									value={user_email}
									onChange={(e) => this.handleChangeUserData(e, "userEmail")}
									readOnly={true}
								/>
							</div>
							<div className="change_password_row">
								<CustomInput
									parentClassName="comment_input_field"
									errorLabel={this.state.passwordError}
									name="password"
									type={this.state.oldPasstype == "text" ? "text" : "password"}
									value={this.state.password}
									onChange={(e) => this.handleChangeUserData(e, "password")}
									placeholder={this.props.t('enterCurrentPassword')}
									autoComplete="off"
								/>
								{!this.state.oldPasswordLock ?
									<span className="passwordEyeIcon" onClick={this.showTextOldPassword}>
										<i className="fa fa-eye-slash" aria-hidden="true"></i>
									</span>
									:
									<span className="passwordEyeIcon" onClick={this.showOldPassword}>
										<i className="fa fa-eye" aria-hidden="true"></i>
									</span>
								}
							</div>
							<div className="change_password_row">
								<div className="input-field-full-box">
									<CustomInput
										parentClassName="comment_input_field"
										errorLabel={this.state.newPasswordError}
										name="newPassword"
										type={this.state.newPasstype == "text" ? "text" : "password"}
										value={this.state.newPassword}
										onChange={(e) => this.handleChangeUserData(e, "newPassword")}
										placeholder={this.props.t('enterNewPassword')}
									/>
									{!this.state.newPasswordLock ?
										<span className="passwordEyeIcon" onClick={this.showTextNewPassword}>
											<i className="fa fa-eye-slash" aria-hidden="true"></i>
										</span>
										:
										<span className="passwordEyeIcon" onClick={this.showNewPassword}>
											<i className="fa fa-eye" aria-hidden="true"></i>
										</span>
									}
								</div>
							</div>
							<div className="change_password_row">
								<div className="input-field-full-box">
									<CustomInput
										parentClassName="comment_input_field"
										errorLabel={this.state.confirmPasswordError}
										name="confirmPassword"
										type={this.state.newConfrimPasstype == "text" ? "text" : "password"}
										value={this.state.confirmPassword}
										onChange={(e) => this.handleChangeUserData(e, "confirmPassword")}
										placeholder={this.props.t('reEnterPassword')}
									/>
									{!this.state.newConfrimPasswordLock ?
										<span className="passwordEyeIcon" onClick={this.showTextNewConfrimPassword}>
											<i className="fa fa-eye-slash" aria-hidden="true"></i>
										</span>
										:
										<span className="passwordEyeIcon" onClick={this.showNewConfrimPassword}>
											<i className="fa fa-eye" aria-hidden="true"></i>
										</span>
									}
								</div>
							</div>
						</div>
						<div className='pass-divider'></div>
						<div className='pass-right-box'>
							<h5>Password Hints:</h5>
							<ul className='password-inner-box'>
								<li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
								<li><span>Require numbers</span></li>
								<li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
								<li><span>Require uppercase letters</span></li>
								<li><span>Require lowercase letters</span></li>
							</ul>
						</div>
					</div>
					<div className="col-md-12 modfooterbtn">
						<button type="button" className="savebtn" onClick={this.changePasswordfromSubmitData}>{this.props.t('submit')}</button>
						<button type="button" className="cancelbtn" onClick={this.props.closeChangePassword}>{this.props.t('cancel')}</button>
					</div>
				</div>
			</>
		);
	}
}

ChangePasswordContent.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
	className: PropTypes.string,
	headerclassName: PropTypes.string,
	bodyClassName: PropTypes.string,
	headerContent: PropTypes.string,
	formData: PropTypes.object,
	errorData: PropTypes.object,
	handleChange: PropTypes.func,
	submit: PropTypes.func
}

ChangePasswordContent.defaultProps = {
	className: "change_password_modal",
	headerclassName: "close_btn_icon",
	buttonClassName: "btn btn-primary",
	headerContent: "Change Password",
	BodyContent: "",
	buttonContent: "",
	bodyClassName: ""
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials
	};
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken })
	(withNamespaces()(ChangePasswordContent));