import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datepicker';
import DatePicker from 'react-datepicker';
import '../Public/css/common.scss';


const CommonDateTimePicker = ({ selected, onChange, showTimeSelect, className, dateFormat, timeIntervals, placeholderText, minDate, maxDate, timeFormat, showTimeSelectOnly, disabled, dateRange, isClearable, startDate, endDate, minTime, maxTime, isDateEnabled, popperClassName, popperPlacement, positionFixed, onBlur }) => {

    return (
        <div className={className}>
            {!dateRange ?
                <DateTimePicker
                    selected={selected}
                    onChange={onChange}
                    showTimeSelect={showTimeSelect}
                    dateFormat={dateFormat}
                    timeIntervals={timeIntervals}
                    placeholderText={placeholderText}
                    minDate={minDate}
                    maxDate={maxDate}
                    timeFormat={timeFormat}
                    showTimeSelectOnly={showTimeSelectOnly}
                    disabled={disabled}
                    isClearable={isClearable}
                    minTime={minTime}
                    maxTime={maxTime}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperClassName={popperClassName}
                    popperPlacement={popperPlacement}
                    positionFixed={positionFixed}
                /> : <DatePicker
                    selected={selected}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectsRange
                    isClearable={isClearable}
                    placeholderText={placeholderText}
                    dateFormat={dateFormat}
                    filterDate={isDateEnabled}
                    popperClassName={popperClassName}
                    popperPlacement={popperPlacement}
                    positionFixed={positionFixed}
                    onBlur={onBlur}
                />
            }
        </div>
    );
};

CommonDateTimePicker.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

CommonDateTimePicker.defaultProps = {
    className: "",
    selected: "",
    dateFormat: "dd-MM-yyyy",
    timeIntervals: 15,
    placeholderText: "Select date and time",
    minDate: new Date(),
    maxDate: "",
    className: "custom-datepicker",
    showTimeSelect: true,
    timeFormat: "HH:mm:ss a",
    showTimeSelectOnly: false,
    disabled: false,
    selectsRange: true,
    dateRange: false,
    isClearable: false,
    startDate: null,
    endDate: null,
    maxTime: "",
    minTime: "",
    isDateEnabled: "",
    popperClassName: "",
    popperPlacement: "",
    positionFixed: false,
    onBlur: () => { },
}

export default CommonDateTimePicker;
