import React, { useState, useEffect } from 'react';
import CustomInput from '../../../Utility/Components/CustomInput';
import Utility from '../../../Utility/Utility';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { setToken, logOutApp } from '../Actions/LoginAction';
import { connect } from 'react-redux';
import * as LoginController from '../Controller/LoginController'
import '../Assets/css/logindoc.scss';
import '../Assets/css/loginresponsivedoc.scss';
import { withNamespaces } from 'react-i18next';
import ValidateUtility from '../../../Utility/ValidateUtility';
import { useParams } from 'react-router-dom';


const ForgotPassword = (props) => {
    const { type } = useParams();
    const [username, setusername] = useState("")
    const [phone_number, setphone_number] = useState("")
    const [state, setState] = useState({
        passwordResetCode: "",
        password: "",
        retypePassword: "",
        usernameError: "",
        passwordResetCodeError: "",
        passwordError: "",
        retypePasswordError: "",
        hideShowChangePasswordPanel: false,
        passwordLock: false,
        rePasswordLock: false,
        passType: "password",
        reTypePassType: "password"
    })

    const { loaderStateTrue, loaderStateFalse, history, logOutApp } = props;

    //forgot password input field handle change event 
    const handleChange = (e) => {
        if (e.target.name == "username") {
            if (e.target.name != "") {
                setusername(e.target.value)
            } else {
                setusername("")
            }
        }

        //change password
        if (e.target.name == "password") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    passwordError: `${props.t("passwordError")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    passwordError: ""
                }))
            }
        }
        if (e.target.name == "retypePassword") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    retypePasswordError: `${props.t("retypepasswordError")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    retypePasswordError: ""
                }))
            }
        }
        if (e.target.name == "passwordResetCode") {
            if (e.target.value == "") {
                setState(prev => ({
                    ...prev,
                    passwordResetCodeError: `${props.t("enterverificationCode")}`
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    passwordResetCodeError: ""
                }))
            }
        }

        if (e.target.name != "username") {
            setState(prev => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    // forget password api call
    const forgotPassword = async () => {
        if (ValidateUtility.validate_email(username.trim())) {
            try {
                let data = {}
                if (username != "") {
                    data['username'] = username.trim();
                }
                if (username == "") {
                    data['username'] = phone_number;
                }
                data['application_platform'] = 'admin';
                loaderStateTrue();
                LoginController.forgotPassword(data).then((response) => {
                    if (response.success) {
                        Utility.toastNotifications(response.message, "Success", "success");
                        if (response.message == "Username and a temporary password have been sent to your email. please log in using the credentials and change your password.") {
                            props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
                        } else {
                            setState(prev => ({
                                ...prev,
                                hideShowChangePasswordPanel: true
                            }))
                        }
                    } else {
                        Utility.toastNotifications(response.message, "Error", "error");
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    loaderStateFalse();
                });

            } catch (error) {
                if (error.code == "UserNotFoundException") {
                    Utility.toastNotifications("User not found", "Error", "error")
                } else {
                    Utility.toastNotifications(error.message, "Error", "error")
                }
            }
        } else {
            setState(prev => ({
                ...prev,
                usernameError: props.t('validEmail')
            }))
        }

    }

    //change password api fn
    const requestForNewPassword = async () => {
        const { passwordResetCode, password } = state;
        if (validationForPasswordChange()) {
            try {
                let data = {}
                if (username != "") {
                    data['username'] = username.trim();
                }
                if (username == "") {
                    data['username'] = phone_number;
                }
                data['password'] = password.trim();
                data['confirmation_code'] = passwordResetCode;
                loaderStateTrue();
                LoginController.changePassword(data).then((response) => {
                    loaderStateFalse();
                    if (response.success) {
                        Utility.toastNotifications(response.message, "Success", "success");
                        history.push("/");
                    } else {
                        Utility.toastNotifications(response.message, "Error", "error");
                    }

                }).catch((error) => {
                    loaderStateFalse();
                });

            } catch (error) {
                if (error.code == "UserNotFoundException") {
                    Utility.toastNotifications("User not found", "Error", "error")
                } else {
                    Utility.toastNotifications(error.message, "Error", "error")
                }
            }
        }

    }

    //change password api validation before submission
    const validationForPasswordChange = () => {
        let valid = true;
        const { passwordResetCode, password, retypePassword } = state;

        if (password.trim() == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                passwordError: `${props.t('passwordError')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                passwordError: ""
            }))
        }
        if (retypePassword.trim() == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                retypePasswordError: `${props.t('retypepasswordError')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                retypePasswordError: ""
            }))
        }

        if ((password.trim() != "" && retypePassword.trim() != "") && password.trim() != retypePassword.trim()) {
            valid = false;
            setState(prev => ({
                ...prev,
                retypePasswordError: `${props.t('passwordnotmatch')}`,
                passwordError: `${props.t('passwordnotmatch')}`
            }))
        }
        if (passwordResetCode == "") {
            valid = false;
            setState(prev => ({
                ...prev,
                passwordResetCodeError: `${props.t('enterverificationCode')}`
            }))
        } else {
            setState(prev => ({
                ...prev,
                passwordResetCodeError: ""
            }))
        }
        return valid;
    }
    const { hideShowChangePasswordPanel } = state;

    //login page redirection fn
    const back_to_login = () => {
        props.history.push("/")
    }

    //language choose selection fn
    const lanOnSelect = (lng) => {
        let confirmation = false;
        confirmation = window.confirm(`${props.t('areYouSureYouWantToReload')}`);
        if (confirmation) {
            i18n.changeLanguage(lng);
            let splitUrl = props.match.url.split('/');
            props.history.push(`/${lng}/${splitUrl[2]}`);
            window.location.reload();
        }

    }

    //show password or hide password eye icon onchange fn
    const hideShowEyeButton = (callFrom, type) => {
        if (type == "text") {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: true,
                    passType: "text"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: true,
                    reTypePassType: "text"
                }))
            }

        } else {
            if (callFrom == "pass") {
                setState(prev => ({
                    ...prev,
                    passwordLock: false,
                    passType: "password"
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    rePasswordLock: false,
                    reTypePassType: "password"
                }))
            }
        }
    }


    //if valid email is comming url params then set the email on email address field
    useEffect(() => {
        if (ValidateUtility.validate_email(type.trim())) {
            setusername(type.trim());
        }
    }, [])

    return (

        <div className="appContainer">
            <div className="loginBackground ForgotPage">
                <div className={hideShowChangePasswordPanel == false ? "appContainerInner appContainerInnerForgot" : "appContainerInner appContainerInnerForgot appContainerInnerRetype"}>
                    <div className="left_section login_box_inner white_background forgotpass">
                        <div className="logo-box-inner">
                            <div className="titleposition">
                                <img src={require('../../../Utility/Public/images/stayingLogo.png')} className="logoInner" />
                            </div>
                        </div>
                        <div className="itemsContainer login_left_inner">
                            <div className="forgotFormDiv">
                                {hideShowChangePasswordPanel == false ?
                                    <div>
                                        <CustomInput
                                            parentClassName="loginFormInnerDiv input_field_small_size emaillabel"
                                            onChange={handleChange}
                                            name="username"
                                            value={username != "" ? username : phone_number}
                                            labelName="Email"
                                            errorLabel={state.usernameError}
                                            labelPresent={true}
                                            requiredStar={false}
                                        />
                                        <div className="forgor_btn_inner_div">
                                            <button className="login-btn btn btn-primary" onClick={forgotPassword}>{props.t('resetPasswordButton')}</button>
                                        </div>
                                        <div className="back_inner_div">
                                            <p className="backtolog" onClick={back_to_login}><span>{props.t('resetPasswordbackToLogin')}</span></p>
                                        </div>
                                    </div> :
                                    <div>
                                        <CustomInput
                                            parentClassName="loginFormInnerDiv input_field_small_size email_disable_field"
                                            name="username"
                                            value={state.username}
                                            readOnly={true}
                                            placeholder={props.t('emailIdPlaceHolder')}
                                        />
                                        <CustomInput
                                            parentClassName="loginFormInnerDiv input_field_small_size"
                                            onChange={handleChange}
                                            name="passwordResetCode"
                                            value={state.passwordResetCode}
                                            placeholder={props.t('Verificationcode')}
                                            errorLabel={state.passwordResetCodeError}
                                        />
                                        <div className='positionRelative'>
                                            <CustomInput
                                                parentClassName="loginFormInnerDiv input_field_small_size"
                                                onChange={handleChange}
                                                name="password"
                                                type={state.passType == "text" ? "text" : "password"}
                                                value={state.password}
                                                errorLabel={state.passwordError}
                                                placeholder={props.t('NewpasswordPlaceHolder')}
                                            />
                                            {!state.passwordLock ?
                                                <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "text") }}>
                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                </span>
                                                :
                                                <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("pass", "password") }}>
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            }
                                        </div>
                                        <div className='positionRelative'>
                                            <CustomInput
                                                parentClassName="loginFormInnerDiv input_field_small_size"
                                                onChange={handleChange}
                                                name="retypePassword"
                                                type={state.reTypePassType == "text" ? "text" : "password"}
                                                value={state.retypePassword}
                                                placeholder={props.t('RetypenewpasswordPlaceHolder')}
                                                errorLabel={state.retypePasswordError}
                                            />
                                            {!state.rePasswordLock ?
                                                <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "text") }}>
                                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                </span>
                                                :
                                                <span className="passwordEyeIcon" onClick={() => { hideShowEyeButton("retypepass", "password") }}>
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </span>
                                            }
                                        </div>
                                        <div className="forgor_btnBoxes forgotpassmodify">
                                            <div className="forgor_btn_inner_div bottom15">
                                                <button className="login-btn btn btn-primary" onClick={requestForNewPassword}>{props.t('ChangePasswordButton')}</button>
                                            </div>
                                            <div className="forgor_btn_inner_div resendcodebtn">
                                                <button className="login-btn btn btn-primary resendverificationbtn" onClick={forgotPassword}>{props.t('ResendverificationcodeButton')}</button>
                                            </div>
                                            <div className="back_inner_div forgotPassBX">
                                                <p className="backtolog" onClick={back_to_login}><span>{props.t('resetPasswordbackToLogin')}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="loglan langdropdown">
                        </div>
                        {hideShowChangePasswordPanel ?
                            <div className='password-rules-box forgot-rule-box'>
                                <div className='pass-right-box'>
                                    <h5>Password Hints:</h5>
                                    <ul className='password-inner-box'>
                                        <li><span>Minimum length</span>, which must be at least 6 characters but fewer than 99 characters</li>
                                        <li><span>Require numbers</span></li>
                                        <li><span>Require a special character</span> {`from this set: = + - ^ $ * . [ ] { } ( ) ?  ! @ # % & / \ , > < ' : ; | _ ~ `}</li>
                                        <li><span>Require uppercase letters</span></li>
                                        <li><span>Require lowercase letters</span></li>
                                    </ul>
                                </div>
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,

    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, logOutApp })(withNamespaces()(ForgotPassword));