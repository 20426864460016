import React, { useEffect, useState, useRef } from 'react'
import CustomInput from '../Components/CustomInput';
import AutosuggestComponent from '../Components/AutosuggestComponent';
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CustomButton from './CustomButton';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalGlobal from './ModalGlobal';
import ImageCropContent from './ImageCropContent';
import ErrorBoundary from './ErrorBoundary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../Actions/AllAction';
import Utility from '../Utility';
import PhoneNumberWithCountryCode from './PhoneNumberWithCountryCode';



function ProfileDetailsContent(props) {
	const profileContentRef = useRef(true);
	const [addProfileImagePreviewShow, setAddProfileImagePreviewShow] = useState(false);
	const [imageCropModalFlag, setImageCropModalFlag] = useState(false);
	const [src, setSrc] = useState(null);
	const [addprofileImageSelected, setAddprofileImageSelected] = useState(require('../../Utility/Public/images/profile-back.png'));
	const [crop, setCrop] = useState({
		unit: '%',
		width: 30,
	})


	const [croppedImageUrl, setCroppedImageUrl] = useState("");
	const imageRef = useRef();
	const [formData, setFormData] = useState({
		"first_name": "",
		"last_name": "",
		"email": "",
		"contact_number": "",
		"profile_image": "",
		"address": "",
		"city": "",
		"zip": "",
		"country": ""
	})

	const [errorFormData, setErrorFormData] = useState({
		"first_name": "",
		"last_name": "",
		"email": "",
		"contact_number": "",
		"profile_image": "",
		"address": "",
		"city": "",
		"zip": "",
		"country": ""
	})




	useEffect(() => {
		if (profileContentRef.current) {
			profileContentRef.current = false;
			getPersonalProfileDataFn();
		}
	}, []);

	const getPersonalProfileDataFn=()=>{
		
	}

	const setPersonalProfileDataFn = () => {
		const { userCredentials } = props
		let tempFormData = { ...formData };
		let tempErrorFormData = { ...errorFormData }
		let userDetails = userCredentials?.user_details;
		if (userDetails.hasOwnProperty('first_name') && userDetails.first_name != null) {
			tempFormData = { ...tempFormData, "first_name": userDetails.first_name }
			tempErrorFormData = { ...tempErrorFormData, "first_name": "" }
		}
		if (userDetails.hasOwnProperty('last_name') && userDetails.last_name != null) {
			tempFormData = { ...tempFormData, "last_name": userDetails.last_name }
			tempErrorFormData = { ...tempErrorFormData, "last_name": "" }
		}
		if (userDetails.hasOwnProperty('user_email') && userDetails.user_email != null) {
			tempFormData = { ...tempFormData, "email": userDetails.user_email }
			tempErrorFormData = { ...tempErrorFormData, "email": "" }
		}
		if (userDetails.hasOwnProperty('contact_number') && userDetails.contact_number != null) {
			tempFormData = { ...tempFormData, "contact_number": userDetails.contact_number.substring((userDetails.contact_number).length - 12, userDetails.contact_number.length) }
			tempErrorFormData = { ...tempErrorFormData, "contact_number": "" }
		}
		if (userDetails.hasOwnProperty('profile_img_url') && userDetails.profile_img_url != null && userDetails.profile_img_url.file_obj && userDetails.profile_img_url.file_obj != null) {
			tempFormData = { ...tempFormData, "profile_image": userDetails.profile_img_url }
			tempErrorFormData = { ...tempErrorFormData, "profile_image": "" }
			setAddprofileImageSelected(userDetails.profile_img_url ? userDetails.profile_img_url.file_obj : require('../../Utility/Public/images/profile-back.png'))
		}

		setFormData(tempFormData)
		setErrorFormData(tempErrorFormData)
	}

	const addInputProfileImageChanged = (event) => {
		let targetFileSplit = event.target.files[0].name.split('.');
		let lastElement = targetFileSplit.pop();
		let user_profile_image = {
			"file_name": "",
			"file_obj": ""
		};
		if (lastElement == 'JPEG' || lastElement == 'jpeg' || lastElement == 'jpg' || lastElement == 'JPG' || lastElement == 'png' || lastElement == 'PNG' || lastElement == '') {
			const fsize = event.target.files[0].size;
			const file = Math.round((fsize / 1024));
			if (file >= 300) {
				Utility.toastNotifications(props.t('imageUploadAlert'), "Warning", "warning");
			} else {
				setImageCropModalFlag(true)
				if (event.target.files && event.target.files.length > 0) {
					const reader = new FileReader();
					reader.addEventListener('load', () =>
						setSrc(reader.result)
					);
					reader.readAsDataURL(event.target.files[0]);
					user_profile_image["file_name"] = event.target.files[0].name
					user_profile_image["file_obj"] = ""
					formData['profile_image'] = user_profile_image
					errorFormData['profile_image'] = ""
					setAddProfileImagePreviewShow(true)
				}
			}

		} else {
			formData['profile_image'] = ""
			errorFormData['profile_image'] = props.t('invalidField'),
				setAddProfileImagePreviewShow(false)
			setAddprofileImageSelected('')
		}
	}

	const onImageLoaded = (image) => {
		if (image) {
			imageRef.current = image
		}
	};

	const onCropComplete = (crop) => {
		makeClientCrop(crop);
	};

	const onCropChange = (crop, percentCrop) => {
		setCrop(crop)
	};


	const imageCropModalShow = () => {
		setImageCropModalFlag(true)
	}

	const makeClientCrop = async (crop) => {
		if (imageRef && crop.width && crop.height) {
			const croppedImageUrl = await getCroppedImg(
				imageRef.current,
				crop,
				'newFile.jpeg'
			);
			let user_profile_image = {}
			setAddprofileImageSelected(croppedImageUrl)
			user_profile_image["file_name"] = formData.profile_image.file_name
			user_profile_image["file_obj"] = addprofileImageSelected
			formData["profile_image"] = user_profile_image

		}
	}

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);

		const base64Image = canvas.toDataURL('image/jpeg');
		return base64Image
	}


	const imageCropModalHide = () => {
		formData['profile_image'] = "",
			errorFormData['profile_image'] = "",
			setImageCropModalFlag(false)
		setAddprofileImageSelected(require('../../Utility/Public/images/profile-back.png'))
		setAddProfileImagePreviewShow(false),
			//new add
			setSrc(null),
			setCrop({
				unit: '%',
				width: 30,
			}),
			setCroppedImageUrl("")
	}

	const imageCropDataSave = () => {
		setImageCropModalFlag(false)
	}

	const handleChangeFn = (e, type) => {
		let tempFormData = { ...formData };
		let tempErrorFormData = { ...errorFormData }

		if (type == "first_name") {
			var expr = /^[a-zA-Z ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['first_name'] = ""
					tempFormData["first_name"] = ""
				} else {
					tempFormData["first_name"] = e.target.value
					tempErrorFormData['first_name'] = ""
				}
			}
		}

		if (type == "last_name") {
			var expr = /^[a-zA-Z ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['last_name'] = ""
					tempFormData["last_name"] = ""
				} else {
					tempFormData["last_name"] = e.target.value
					tempErrorFormData['last_name'] = ""
				}
			}
		}

		if (type == "address") {
			var expr = /^[a-zA-Z0-9 ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['address'] = ""
					tempFormData["address"] = ""
				} else {
					tempFormData["address"] = e.target.value
					tempErrorFormData['address'] = ""
				}
			}
		}

		if (type == "city") {
			var expr = /^[a-zA-Z ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['city'] = ""
					tempFormData["city"] = ""
				} else {
					tempFormData["city"] = e.target.value
					tempErrorFormData['city'] = ""
				}
			}
		}

		if (type == "country") {
			var expr = /^[a-zA-Z ]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['country'] = ""
					tempFormData["country"] = ""
				} else {
					tempFormData["country"] = e.target.value
					tempErrorFormData['country'] = ""
				}
			}
		}

		if (type == "zip") {
			var expr = /^[0-9]*$/;
			if (!expr.test(e.target.value)) {

			} else {
				if (e.target.value == "") {
					tempErrorFormData['zip'] = ""
					tempFormData["zip"] = ""
				} else {
					let zip_length = e.target.value.length;
					let zipValidate = false;
					if (zip_length == 6) {
						zipValidate = true;
					}
					if (zipValidate) {
						tempFormData["zip"] = e.target.value
						tempErrorFormData['zip'] = ""
					} else {
						tempFormData["zip"] = (e.target.value).substring(0, 6)
						tempErrorFormData['zip'] = ""
					}
				}
			}
		}

		if (type == "contact_number") {
			if (e != "") {
				let phoneValidate = ValidateUtility.validate_Phone_Number_without_plus(e);
				if (phoneValidate) {
					tempFormData["contact_number"] = e
					tempErrorFormData['contact_number'] = ""
				} else {
					tempFormData["contact_number"] = e
					tempErrorFormData['contact_number'] = "Enter valid phone number"
				}
			} else {
				tempFormData["contact_number"] = ""
				tempErrorFormData['contact_number'] = "Required Field";
			}
		}


		setFormData(tempFormData)
		setErrorFormData(tempErrorFormData)
	}


	const { t, } = props;
	return (
		<>
			<div className='columnBoxRight modalinnerbody'>
				<div className="innertopPanelBoxx">
					<div className="col-md-12">
						<div className='row'>
							<div className="col-md-8">
								<div className='row'>
									<div className='col-sm-6'>
										<div className='templateBoxHalf withoutlabel'>
											<CustomInput
												parentClassName="input_field_inner"
												labelName="First Name"
												name="first name"
												type="text"
												value={formData.first_name}
												labelPresent={true}
												onChange={(e) => { handleChangeFn(e, "first_name") }}
												id="name"
												placeholder="First Name"
												readOnly={false}
											/>
											<div className="errorClass error_div">{errorFormData.nameError}</div>
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='templateBoxHalf withoutlabel'>
											<CustomInput
												parentClassName="input_field_inner"
												labelName="Last Name"
												name="last_name"
												type="text"
												value={formData.last_name}
												labelPresent={true}
												onChange={(e) => { handleChangeFn(e, "last_name") }}
												id="last_name"
												placeholder="Last Name"
												requiredStar={true}
											/>
											<div className="errorClass error_div">{errorFormData.last_name}</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-sm-6'>
										<div className='templateBoxHalf withoutlabel'>
											<ErrorBoundary title="PhoneInput Error">
												<PhoneNumberWithCountryCode
													country={process.env.PHONE_NUMBER_COUNTRY_SET}
													value={formData.contact_number}
													onChange={(e) => handleChangeFn(e, 'contact_number')}
													error={errorFormData.contact_number}
													labelShow={true}
													requirStart={true}
												/>
											</ErrorBoundary>
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='templateBoxHalf withoutlabel'>
											<CustomInput
												parentClassName="input_field_inner"
												labelName="Address"
												name="address"
												type="text"
												value={formData.address}
												labelPresent={true}
												onChange={(e) => { handleChangeFn(e, "address") }}
												id="address"
												placeholder="Address"
												requiredStar={false}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='col-sm-4'>
								<div className='user_add_img userprofileimg myProfileImg'>
									<div className="userProfileImg">
										{!addProfileImagePreviewShow ?
											<OverlayTrigger overlay={<Tooltip>Upload Admin Image</Tooltip>}>
												<label className="custom-file-upload">
													<span className="filetext">
														<img src={addprofileImageSelected}
														/>
													</span>
													<span className="plusicon">
														<i className="fa fa-plus" aria-hidden="true"></i>
														<input type="file" onChange={addInputProfileImageChanged} />
													</span>
												</label></OverlayTrigger> :
											<OverlayTrigger overlay={<Tooltip>Admin Image</Tooltip>}>
												<label className="custom-file-upload">
													<img src={addprofileImageSelected} />
													<span className="plusicon">
														<i className="fa fa-plus" aria-hidden="true"></i>
														<input type="file" onChange={addInputProfileImageChanged} />
													</span>
												</label></OverlayTrigger>}

										<div className="col-md-12 errorClass error_div">{errorFormData.profile_image}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-12">
						<div className='row'>
							<div className='col-sm-4'>
								<div className='templateBoxHalf withoutlabel'>
									<CustomInput
										parentClassName="input_field_inner"
										labelName="City"
										name="city"
										type="text"
										value={formData.city}
										labelPresent={true}
										onChange={(e) => { handleChangeFn(e, "city") }}
										id="city"
										placeholder="City"
										requiredStar={false}
									/>
									<div className="errorClass error_div">{errorFormData.city}</div>
								</div>
							</div>
							<div className='col-sm-4'>
								<div className='templateBoxHalf withoutlabel'>
									<CustomInput
										parentClassName="input_field_inner"
										labelName="Zip code"
										name="zip_code"
										type="text"
										value={formData.zip}
										labelPresent={true}
										onChange={(e) => { handleChangeFn(e, "zip") }}
										id="zip_code"
										placeholder="Zip code"
										requiredStar={false}
									/>
									<div className="errorClass error_div">{errorFormData.zip}</div>
								</div>
							</div>
							<div className='col-sm-4'>
								<div className='templateBoxHalf withoutlabel'>
									<CustomInput
										parentClassName="input_field_inner"
										labelName="Country"
										name="country"
										type="text"
										value={formData.country}
										labelPresent={true}
										onChange={(e) => { handleChangeFn(e, "country") }}
										id="country"
										placeholder="Country"
										requiredStar={false}
									/>
									<div className="errorClass error_div">{errorFormData.country}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ErrorBoundary title="ModalGlobal Error">
					<ModalGlobal
						show={imageCropModalFlag}
						onHide={imageCropModalHide}
						onCancel={imageCropModalHide}
						onSave={imageCropDataSave}
						className="modalcustomize cropmodalcontent"
						bodyClassName="cropmodalcontentbody"
						headerclassName="close_btn_icon"
						title={props.t('cropimage')}
						footer={true}
						closeButton={true}
						saveButtonLabel={props.t('crop')}
						saveButtonClassName="delconfirmbtn btn btn-primary"
						cancelButtonClassName="delcancelbtn btn btn-primary"
						body={
							<>
								<ErrorBoundary title="ModalGlobal Error">
									<ImageCropContent
										onImageLoaded={onImageLoaded}
										onComplete={onCropComplete}
										onCropChange={onCropChange}
										crop={crop}
										croppedImageUrl={croppedImageUrl}
										src={src}
										onCropComplete={onCropComplete}
										imageCropModalShow={imageCropModalShow}
									/>
								</ErrorBoundary>
							</>
						}
					/>
				</ErrorBoundary>

			</div>

		</>
	)

}

ProfileDetailsContent.defaultProps = {
	userInformationTitle: true,
	cancelBtnDisplay: true,
	confirmButtonTitle: "Save",
	markAsShow: true,
	closeButtonShow: true,
	saveButtonDisplay: true,
	requiredPhoneStar: false
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		token: globalState.LoginReducer.token
	};
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(ProfileDetailsContent));