import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Accordion } from "react-bootstrap";
import { handleActiveLink, handleLeft } from '../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp, userrole } from '../../GlobalModules/Login/Actions/LoginAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Utility from '../Utility'

class Left extends Component {
	constructor(props) {
		super(props);
		this.handleBar = this.handleBar.bind(this);
		this.state = {
			toggleType: "",
			activeClass: "",
			accName: "",
			subMenuIcon: ""
		}
	}

	toggle = (params, linkType) => {
		if (linkType === "") {

			this.setState({
				collapsed2: true,
				accName: ""
			}, () => {
				this.props.handleActiveLink(this.state.activeClass, this.state.accName)

			});
		}
		localStorage.setItem("selectedTabName", "");
		this.setState({
			activeClass: params
		}, () => {
			this.props.handleActiveLink(this.state.activeClass, this.state.accName)
		});
	}

	handleBar = () => {
		this.props.handleLeft(!this.props.leftbar)
	}
	validationPermission = (eventKey) => {
		const { t, roleWisePermission } = this.props;
		let valid = false;
		if (roleWisePermission && roleWisePermission.hasOwnProperty(eventKey) && roleWisePermission[eventKey].read_write_permission != "") {
			valid = true;
		}
		return valid
	}



	leftUi = () => {
		const { t, roleWisePermission, userCredentials } = this.props;
		const { activeClass, accName } = this.props.activeLink;
		let arry = []
		let settingsPermission = false;
		if (Object.keys(Utility.getRoleObjectByEventKey('booking_rules')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('cancellation_rules')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('amenities')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('tax')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('role')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('room_facility')).length > 0) {
			settingsPermission = true;
		}
		//For agent
		let settingsPermissionForAgent = false;
		if (Object.keys(Utility.getRoleObjectByEventKey('role')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('advance_percent')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('cancellation_rules')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('amenities')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('tax')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('room_facility')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('account_info')).length > 0) {
			settingsPermissionForAgent = true;
		}

		let homePermission = false;
		if (userCredentials.user_details?.user_type == "admin" || userCredentials.user_details?.user_type == "staff") {
			if (Object.keys(Utility.getRoleObjectByEventKey('booking_room')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('quotation')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('block_room')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('branch')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('room_type')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('staff')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('room_facility')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('credit_note')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('report')).length > 0) {
				homePermission = true;
			}
		} else if (userCredentials.user_details?.user_type == "app_admin") {
			if (Object.keys(Utility.getRoleObjectByEventKey('hotels')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('admin')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('customer_rating')).length > 0) {
				homePermission = true;
			}
		}
		let homePermissionForAgent = false;
		if (userCredentials.user_details?.user_type == "agent" || userCredentials.user_details?.user_type == "agent_staff") {
			if (Object.keys(Utility.getRoleObjectByEventKey('branch')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('room_type')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('booking_room')).length > 0 || Object.keys(Utility.getRoleObjectByEventKey('quotation')).length > 0) {
				homePermissionForAgent = true;
			}
		}

		// Select Settings Menu
		let settingActionPage = "role";
		if (settingsPermission) {
			const roleObject = Utility.getRoleObjectByEventKey('role');
			if (Object.keys(roleObject).length === 0) {
				let settingActionPageFlag = true;
				["booking_rules", "cancellation_rules", "tax", "room_facility", "amenities"].forEach(tabMenu => {
					if (settingActionPageFlag && Object.keys(Utility.getRoleObjectByEventKey(tabMenu)).length > 0) {
						settingActionPageFlag = false;
						settingActionPage = tabMenu;
					}
				});
			}
		}

		// Select Settings Menu For Agent
		let settingActionPageForAgent = "role";
		if (settingsPermissionForAgent) {
			const roleObject = Utility.getRoleObjectByEventKey('role');
			if (Object.keys(roleObject).length === 0) {
				let settingActionPageFlagForAgent = true;
				["advance_percent", "cancellation_rules", "tax", "room_facility", "amenities", "account_info"].forEach(tabMenu => {
					if (settingActionPageFlagForAgent && Object.keys(Utility.getRoleObjectByEventKey(tabMenu)).length > 0) {
						settingActionPageFlagForAgent = false;
						settingActionPageForAgent = tabMenu;
					}
				});
			}
		}

		// Select Home Menu
		let homeActionPage = "booking_room";
		if (Object.keys(Utility.getRoleObjectByEventKey('booking_room')).length === 0) {
			let homeActionPageFlag = true;
			["quotation", "block_room", "branch", "room_type", "staff", "credit_note"].forEach(tabMenu => {
				if (homeActionPageFlag && Object.keys(Utility.getRoleObjectByEventKey(tabMenu)).length > 0) {
					homeActionPageFlag = false;
					homeActionPage = tabMenu;
				}
			});
		}

		//for agent
		let homeActionPageForAgent = "agent_booking";
		if (Object.keys(Utility.getRoleObjectByEventKey('booking_room')).length === 0) {
			let homeActionPageForAgentFlag = true;
			const tabMenus = ['agent_booking', 'quotations', 'branch', 'room_type'];
			const keyMapping = {
				'agent_booking': 'booking_room',
				'quotations': 'quotation'
			};
			tabMenus.forEach(tabMenu => {
				const roleKey = keyMapping[tabMenu] || tabMenu;

				if (homeActionPageForAgentFlag && Object.keys(Utility.getRoleObjectByEventKey(roleKey)).length > 0) {
					homeActionPageForAgentFlag = false;
					homeActionPageForAgent = tabMenu;
				}
			});
		}


		if (userCredentials.user_details?.is_subscribed && (userCredentials.user_details?.user_type == "admin" || userCredentials.user_details?.user_type == "staff") && Object.keys(Utility.getRoleObjectByEventKey('dashboard')).length > 0) {
			arry.push(
				<Nav.Item className={activeClass === 'dashboard_module' ? "ActiveClass" : "default dashboarditem"} onClick={this.toggle.bind(this, 'dashboard_module', '')} key={0}>
					<Link to={`/${localStorage.getItem('i18nextLng')}/dashboard`} className="nav-link">
						<div className="imgcircle">
							<OverlayTrigger overlay={<Tooltip>Data Insights</Tooltip>}>
								<img src={require('../Public/images/cort.png')} className="sidebaricon" />
							</OverlayTrigger>
						</div>
					</Link>
				</Nav.Item>
			)
		}

		
		if (homePermission && (userCredentials.user_details?.user_type == "app_admin" || userCredentials.user_details?.user_type == "admin" || userCredentials.user_details?.user_type == "staff")) {
			arry.push(
				<Nav.Item className={activeClass === 'home_module' ? "ActiveClass" : "default"} onClick={this.toggle.bind(this, 'home_module', '')} key={1}>
					{userCredentials.user_details?.user_type == "app_admin" ?
						<Link to={`/${localStorage.getItem('i18nextLng')}/home/hotels`} className="nav-link">
							<div className="imgcircle">
								<OverlayTrigger overlay={<Tooltip>Reservation & Property Management</Tooltip>}>
									<img src={require('../Public/images/home.png')} className="sidebaricon" />
								</OverlayTrigger>
							</div>
						</Link>
						:
						<Link to={`/${localStorage.getItem('i18nextLng')}/home/${homeActionPage}`} className="nav-link">
							<div className="imgcircle">
								<OverlayTrigger overlay={<Tooltip>Reservation & Property Management</Tooltip>}>
									<img src={require('../Public/images/home.png')} className="sidebaricon" />
								</OverlayTrigger>
							</div>
						</Link>
					}

				</Nav.Item>
			)
		}

		if (homePermissionForAgent && (userCredentials.user_details?.user_type == "agent" || userCredentials.user_details?.user_type == "agent_staff")) {
			arry.push(
				<Nav.Item className={activeClass === 'home_module' ? "ActiveClass" : "default"} onClick={this.toggle.bind(this, 'home_module', '')} key={1}>

					<Link to={`/${localStorage.getItem('i18nextLng')}/agent/${homeActionPageForAgent}`} className="nav-link">
						<div className="imgcircle">
							<OverlayTrigger overlay={<Tooltip>Reservation & Property Management</Tooltip>}>
								<img src={require('../Public/images/home.png')} className="sidebaricon" />
							</OverlayTrigger>
						</div>
					</Link>
				</Nav.Item>
			)
		}
	
		if ((userCredentials.user_details?.is_subscribed || userCredentials.user_details?.user_type == "app_admin") && settingsPermission && (userCredentials.user_details?.user_type == "app_admin" || userCredentials.user_details?.user_type == "admin" || userCredentials.user_details?.user_type == "staff")) {
			arry.push(
				<Nav.Item className={activeClass === 'settings_module' ? "ActiveClass" : "default"} onClick={this.toggle.bind(this, 'settings_module', '')} key={3}>
					{userCredentials.user_details?.user_type == "app_admin" ?
						<Link to={`/${localStorage.getItem('i18nextLng')}/settings/room_facility`} className="nav-link">
							<div className="imgcircle">
								<OverlayTrigger overlay={<Tooltip>Configuration Control pannel</Tooltip>}>
									<img src={require('../Public/images/roleicon.png')} className="sidebaricon" />
								</OverlayTrigger>
							</div>
						</Link>
						:
						<Link to={`/${localStorage.getItem('i18nextLng')}/settings/${settingActionPage}`} className="nav-link">
							<div className="imgcircle">
								<OverlayTrigger overlay={<Tooltip>Configuration Control pannel</Tooltip>}>
									<img src={require('../Public/images/roleicon.png')} className="sidebaricon" />
								</OverlayTrigger>
							</div>
						</Link>
					}
				</Nav.Item>
			)
		}
		if (userCredentials.user_details?.is_subscribed && settingsPermissionForAgent && (userCredentials.user_details?.user_type == "agent" || userCredentials.user_details?.user_type == "agent_staff")) {
			arry.push(
				<Nav.Item className={activeClass === 'settings_module_agent' ? "ActiveClass" : "default"} onClick={this.toggle.bind(this, 'settings_module_agent', '')} key={4}>

					<Link to={`/${localStorage.getItem('i18nextLng')}/agent_settings/${settingActionPageForAgent}`} className="nav-link">
						<div className="imgcircle">
							<OverlayTrigger overlay={<Tooltip>Configuration Control pannel</Tooltip>}>
								<img src={require('../Public/images/roleicon.png')} className="sidebaricon" />
							</OverlayTrigger>
						</div>
					</Link>
				</Nav.Item>
			)
		}



		return arry;
	}


	render() {
		const { activeClass, accName } = this.props.activeLink;
		return (
			<aside id="left_bar_id" className={this.props.leftbar ? 'left_bar_customize left-bar open hide-sm' : 'left_bar_customize left-bar closed hide-sm'}>
				<div className="webtitlelogo"><OverlayTrigger overlay={<Tooltip>THE STAYING</Tooltip>}><img src={require('../Public/images/stayingLogoWhite.png')} className="" /></OverlayTrigger></div>
				<Nav className="flex-column pt-2 side-nav">
					{this.leftUi()}
				</Nav>
			</aside>
		)
	}
}


function mapStateToProps(globalState) {
	return {
		leftbar: globalState.mainReducerData.leftbar,
		activeLink: globalState.mainReducerData.activeLink,
		roleWisePermission: globalState.mainReducerData.roleWisePermission,
		userCredentials: globalState.LoginReducer.userCredentials,
		loginDetails: globalState.LoginReducer

	};
}

export default connect(mapStateToProps, { handleActiveLink, handleLeft, setToken })(Left);