class ValidateUtility {
    //phone number validation
    static validate_Phone_Number = (value) => {
        var number = value;
        var filter = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate phone number without plus sign
    static validate_Phone_Number_without_plus = (value) => {
        var number = value;
        var filter = /^(\+?\d{1,2}[ -]?)?(\(\+?\d{2,3}\)|\+?\d{2,3})?[ -]?\d{8,15}[ -]?\d{3,4}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //email validation function
    static validate_email = (value) => {
        var number = value;
        var filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //numeric value check function
    static isNumeric(num) {
        return !isNaN(num)
    }

    //bank account number check function
    static bankAccountNumberValidation = (value) => {
        let valid = true;
        let number = this.isNumeric(value);
        if (number && value.length > 4) {
            valid = true;
        } else {
            valid = false;
        }
        return valid;
    }

    //validate gstn number fn
    static validate_gstn_Number = (value) => {
        var number = value.toUpperCase();
        var filter = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate pan number fn
    static validate_pan_Number = (value) => {
        var number = value.toUpperCase();
        var filter = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate aadhar number fn
    static validate_aadhar_number = (value) => {
        var number = value;
        var filter = /^\d{12}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate identity number fn
    static validate_identity_card_number = (value) => {
        var number = value;
        var filter = /^[A-Za-z0-9]{8,}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate driving license number fn
    static validate_driving_licence_number = (value) => {
        var number = value;
        var filter = /^[A-Za-z0-9]{6,12}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate ifsc number fn
    static validateIFSC = (value) => {
        var number = value.toUpperCase();
        var filter = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //validate bank account number fn
    static validateBankAccountNumber = (value) => {
        var number = value.toUpperCase();
        var filter = /^\d{9,18}$/;
        if (filter.test(number)) {
            return true;
        }
        else {
            return false;
        }
    }

    //check incoming string date format or not
    static isDateString(str) {
        const dateParts = str.split(/[-/]/);
        if (dateParts.length === 3) {
            const firstPart = parseInt(dateParts[0], 10);
            const secondPart = parseInt(dateParts[1], 10);
            const thirdPart = parseInt(dateParts[2], 10);
            const isValidDate =
                !isNaN(thirdPart) && !isNaN(secondPart) && !isNaN(firstPart) &&
                (
                    (firstPart >= 1 && firstPart <= 31 && secondPart >= 1 && secondPart <= 12) ||
                    (thirdPart >= 1 && thirdPart <= 31 && secondPart >= 1 && secondPart <= 12)
                );

            return isValidDate;
        }

        return false;
    }

    //country code replace from phone number
    static deleteAndBefore(phoneNumber, countryCode) {
        var escapedCountryCode = countryCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        var regex = new RegExp("^" + escapedCountryCode);
        return phoneNumber.replace(regex, "");
    }

}
export default ValidateUtility;