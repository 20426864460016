import LoginActionTypes from '../Utility/LoginActionTypes'
import AllActionTypes from '../../../Utility/AllActionTypes'
import { get, post, put, del } from '../../../Utility/Http';

//set login token in redux
export const setToken = (token, accessToken, expiresIn, refreshToken, userGroup = [], organisationGroup = [], session = '') => async (dispatch) => {

    dispatch({
        type: LoginActionTypes.SET_TOKEN,
        payload: token
    });
    dispatch({
        type: LoginActionTypes.SET_ACCESS_TOKEN,
        payload: accessToken
    });
    dispatch({
        type: LoginActionTypes.SET_TOKEN_EXPIRE_TIME,
        payload: expiresIn
    });
    dispatch({
        type: LoginActionTypes.SET_REFRESH_TOKEN,
        payload: refreshToken
    });
    dispatch({
        type: LoginActionTypes.USER_GROUP,
        payload: userGroup
    });
    dispatch({
        type: LoginActionTypes.ORGANISATION_GROUP,
        payload: organisationGroup
    });
    dispatch({
        type: LoginActionTypes.SET_SESSION,
        payload: session
    });
}

//logout app api and clear redux
export const logOutApp = (accessToken, token) => async (dispatch) => {
    const promise = new Promise((resolve, reject) => {
        del(`${process.env.EXTENDED_URL}users/signout`, "", { "Accesstoken": "", "Authorization": "" }).then((response) => {
            resolve(response.data);
        })
    })

    return promise;

}

//set user credentials in redux
export const setUserCredentials = (value) => async (dispatch) => {
    dispatch({
        type: LoginActionTypes.SET_USER_CREDENTIALS,
        payload: value
    });
}

//set user entry type in redux
export const setUserEntryType = (value) => async (dispatch) => {
    dispatch({
        type: LoginActionTypes.USER_ENTRY_TYPE,
        payload: value
    });
}

//set verson in redux
export const setVersionList = (value) => async (dispatch) => {
    dispatch({
        type: LoginActionTypes.SET_VERSION,
        payload: value
    });

}
