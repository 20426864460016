
export const changePasswordGet = (changePasswordData) => {
    return changePasswordData.data
}

export const userNotificationsGet = (notificationData) =>{
    return notificationData.data
}

export const userNotificationsPatch = (userNotificationsPatchData) =>{
    return userNotificationsPatchData.data;
}
export const userChangePasswordApi = (ChangePasswordData) => {
    return ChangePasswordData.data
}
export const userDetailsGet = (data) => {
    return data.data
}
export const orgListingGet = (data) => {    
    return data.data;
}
export const showNotificationGet = (data) => {    
    return data.data;
}
export const bookingsIndividualDetailsGet = (data) => {    
    return data.data;
}
export const checkLiveOrNotGet = (data) => {
    return data.data;
}
export const branchListGet = (data) => {
    return data.data;
}
export const branchWiseRoomTypeApiGet = (data) => {
    return data.data;
}
export const calenderReportGet = (data) => {
    return data.data;
}
