import React, { Component, lazy, Suspense } from 'react';
import { createHashHistory } from 'history';
import { Routes, Route, Switch, Redirect } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const MfaPage = lazy(() => import('../Modules/Home/Pages/MfaPage'));

LoadingOverlay.propTypes = undefined;
class index extends Component {

    render() {
        return (
            <>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route exact path="/:lng/mfa" component={MfaPage} />
                    </Switch >
                </Suspense >
            </>


        );
    }
}

const mapStateToProps = (globalState) => {
    //console.log("globalState.LoginReducer.version------------->", globalState.LoginReducer.version)
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        accessToken: globalState.LoginReducer.accessToken,
        token: globalState.LoginReducer.token,
        leftbar: globalState.mainReducerData.leftbar,
        //version: globalState.LoginReducer.version
    };
}
// export default LoginIndex;

export default connect(mapStateToProps)(withRouter(index));

function Loading() {
    return (
        <>
            <div className="wrapper">
                <span>Loading</span>
                {/* <LoadingOverlay
          active={true}
          className='lodingOverlayDiv'
          // spinner={<FadeLoader
          //     color={'#ccc !important'}
          //     size={50} margin='0px'
          //     speedMultiplier={0.3}
          // />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.1)',
              zIndex: '9999999999999'
            })
          }}
        ></LoadingOverlay> */}
            </div>
        </>
    );
}
