import React, { Component, lazy, Suspense } from 'react';
import { createHashHistory } from 'history';
import { Routes, Route, Switch, Redirect } from "react-router-dom";

// import DashboardRoute from "../Modules/Dashboard/Routes/DashboardRoute";
// import HomeRoute from "../Modules/Home/Routes/HomeRoute";
// import NotFound from "../../Utility/Pages/NotFound";
// import SettingsRoute from '../Modules/Settings/Routes/SettingsRoute';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';


const DashboardRoute = lazy(() => import('../Modules/Dashboard/Routes/DashboardRoute'));
const HomeRoute = lazy(() => import('../Modules/Home/Routes/HomeRoute'));
const NotFound = lazy(() => import('../../Utility/Pages/NotFound'));
const SettingsRoute = lazy(() => import('../Modules/Settings/Routes/SettingsRoute'));

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

LoadingOverlay.propTypes = undefined;
class index extends Component {
  componentDidMount() {
    if (this.props.token && this.props.token != "") {
      //console.log("---------------------------splitUrl--------------: ", this.props.history.location.pathname.split('/'));
      if (this.props.history.location.pathname.split('/')[2] == "login") {
        this.props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
      }
    }
  }
  render() {
    //console.log("Entry login");
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={DashboardRoute} />
          <Route exact path="/:lng/dashboard" component={DashboardRoute} />
          <Route exact path="/:lng/home/:type?/:branchId?" component={HomeRoute} />
          <Route exact path="/:lng/booking_confirmation/:bookingID?" component={HomeRoute} />
          <Route exact path="/:lng/payment" component={HomeRoute} />
          <Route exact path="/:lng/settings/:type?/:next_step?" component={SettingsRoute} />
          {/* <Suspense fallback={<Loading />}>
          <Route exact path="/404" component={NotFound} />
          <Redirect from="/" to="/404" />
        </Suspense> */}
        </Switch>
      </Suspense>

    );
  }
}

const mapStateToProps = (globalState) => {
  //console.log("globalState.LoginReducer.version------------->", globalState.LoginReducer.version)
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    accessToken: globalState.LoginReducer.accessToken,
    token: globalState.LoginReducer.token,
    leftbar: globalState.mainReducerData.leftbar,
    //version: globalState.LoginReducer.version
  };
}
// export default LoginIndex;

export default connect(mapStateToProps)(withRouter(index));

function Loading() {
  return (
    <>
      <div className="wrapper">
        {/* <span>Loading...</span> */}
        <LoadingOverlay
          active={true}
          className='lodingOverlayDiv'
          // spinner={<FadeLoader
          //     color={'#ccc !important'}
          //     size={50} margin='0px'
          //     speedMultiplier={0.3}
          // />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.1)',
              zIndex: '9999999999999'
            })
          }}
        ></LoadingOverlay>
      </div>
    </>
  );
}
