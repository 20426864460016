import React, { Component } from 'react';
import CustomInput from '../../../../Utility/Components/CustomInput';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../../Login/Actions/LoginAction';
import * as ProfessionalController from './Controller/ProfessionalController'
import Utility from '../../../../Utility/Utility';
import moment from 'moment';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';


class Professional extends Component {
	constructor(props) {
		super(props);
		this.state = {
			professionalTabFormData: {
				emp_id: "",
				working_hours: "",
				joining_date: "",
				end_of_contract: ""
			},
			professionalTabFormDataError: {
				emp_id: "",
				working_hours: "",
				joining_date: "",
				end_of_contract: ""
			},
			button_enabled: false,
			userProfessionalId: "",
			userProfessionalData: {},
			disable: false
		}

	}

	componentDidMount = () => {
		this.userProfessionalInfo();
		this.fetchTabData();
	}

	//user Professional tabwise role permission fn
	async fetchTabData() {
		let disableFlag = false
		const personalTabPermission = await Utility.getRoleObjectBySubEventKey('user', 'professional');
		if (this.props.userCredentials.user_details?.user_type === "staff" && personalTabPermission.length > 0 && personalTabPermission[0].rules == "R") {
			disableFlag = true
		}
		this.setState({
			disable: disableFlag
		});
	}


	//get user profession information api fn
	userProfessionalInfo = () => {
		const { loaderStateTrue, loaderStateFalse, userProfileId } = this.props;
		loaderStateTrue();
		ProfessionalController.userProfessionalInfoGet(userProfileId).then((response) => {
			if (response.success) {
				this.setState({
					userProfessionalId: response.data[0].hasOwnProperty('UserProfInfo') ? response.data[0].UserProfInfo.id : "",
					userProfessionalData: response.data[0].hasOwnProperty('UserProfInfo') ? response.data[0].UserProfInfo : {},
				}, () => {
					this.setUserProfessionalInfoData()
				})
			}
			loaderStateFalse();
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
		});
	}

	//professional data update submit fn
	professionalfromSubmitData = () => {
		this.updateProfessionalDetails()

	}

	//profession data update api fn
	updateProfessionalDetails = () => {
		let valid = this.validationFromData();
		const { loaderStateTrue, loaderStateFalse } = this.props;
		let data = this.formatingDataset();
		data = data[0];
		let type = 'patch'
		loaderStateTrue();
		ProfessionalController.updateProfessionalDetailsUpdate(data, type, this.state.userProfessionalId).then((response) => {
			loaderStateFalse();
			if (response) {
				if (response.success) {
					this.props.detectChangesIndividualTab(false)
					this.props.detectChangesIndividualInnerTab(false)
					this.userProfessionalInfo()
					this.setState({
						button_enabled: false
					}, () => {
					})
					Utility.toastNotifications(response.message, "Success", "success");
				} else {
					Utility.toastNotifications(response.message, "Error", "error")
				}
			}

		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
			if (error.message == "Network Error") {

			}
		});
	}

	//cancel professional tab changes fn
	cancelChangesProfessionalTab = () => {
		this.props.detectChangesIndividualTab(false)
		this.props.detectChangesIndividualInnerTab(false)
		this.setState({
			button_enabled: false
		}, () => { })
	}

	//professional tab input field onchange fn
	handelChange = (e, type) => {
		this.props.detectChangesIndividualTab(true)
		this.props.detectChangesIndividualInnerTab(true)
		const { professionalTabFormData, professionalTabFormDataError } = this.state;
		let tempFormData = Object.assign(professionalTabFormData);
		let tempErrorFormData = Object.assign(professionalTabFormDataError)

		if (type == "emp_id") {
			if (e.target.value == "") {
				tempErrorFormData['emp_id'] = ""
				tempFormData["emp_id"] = ""
			} else {
				tempFormData["emp_id"] = e.target.value
				tempErrorFormData['emp_id'] = ""
			}
		}

		if (type == "working_hours") {
			if (e.target.value == "") {
				tempErrorFormData['working_hours'] = ""
				tempFormData["working_hours"] = ""
			} else {
				tempFormData["working_hours"] = e.target.value
				tempErrorFormData['working_hours'] = ""
			}
		}

		if (type == "joining_date") {
			if (e == "") {
				tempErrorFormData['joining_date'] = ""
				tempFormData["joining_date"] = ""
			} else {
				tempFormData["joining_date"] = e
				tempErrorFormData['joining_date'] = ""
			}
		}

		if (type == "end_of_contract") {
			if (e == "") {
				tempErrorFormData['end_of_contract'] = ""
				tempFormData["end_of_contract"] = ""
			} else {
				tempFormData["end_of_contract"] = e
				tempErrorFormData['end_of_contract'] = ""
			}
		}



		this.setState({
			professionalTabFormData: tempFormData,
			professionalTabFormDataError: tempErrorFormData,
			button_enabled: true
		})
	}

	//user professional info validation check fn 
	validationFromData = () => {
		const { professionalTabFormData, professionalTabFormDataError } = this.state;
		let tempFormData = Object.assign(professionalTabFormData);
		let tempErrorFormData = Object.assign(professionalTabFormDataError)
		let valid = true
		this.setState({
			professionalTabFormDataError: tempErrorFormData
		})
		return valid
	}


	//formatting professional data fn
	formatingDataset = () => {
		const { professionalTabFormData } = this.state
		let dataset = [];
		let hash = {}
		if (professionalTabFormData.emp_id != null && (professionalTabFormData.emp_id).trim() != "") {
			hash['emp_number'] = (professionalTabFormData.emp_id).trim();
		} else {
			hash['emp_number'] = "";
		}
		if (professionalTabFormData.working_hours != "" && professionalTabFormData.working_hours != null) {
			hash['working_hours'] = (professionalTabFormData.working_hours).trim();
		} else {
			hash['working_hours'] = "";
		}
		if (professionalTabFormData.joining_date != "" && professionalTabFormData.joining_date != null) {
			hash['joining_date'] = moment(professionalTabFormData.joining_date.toString()).format('YYYY-MM-DD');
		} else {
			hash['joining_date'] = "";
		}
		hash['user_id'] = this.props.userProfileId;
		dataset.push(hash)
		return dataset;
	}

	//set user professional info data 
	setUserProfessionalInfoData = () => {
		const { userProfessionalData } = this.state
		if (Object.keys(userProfessionalData).length > 0) {
			let joiningDate = ""
			if (userProfessionalData.joining_date == null || userProfessionalData.joining_date == "") {
				joiningDate = "";
			} else {
				joiningDate = new Date(userProfessionalData.joining_date);
			}
			let endOfContract = ""
			if (userProfessionalData.end_of_contract == null || userProfessionalData.end_of_contract == "") {
				endOfContract = "";
			} else {
				endOfContract = new Date(userProfessionalData.end_of_contract);
			}
			this.setState({
				professionalTabFormData: {
					emp_id: userProfessionalData.emp_number,
					working_hours: userProfessionalData.working_hours,
					joining_date: joiningDate,
					end_of_contract: endOfContract,
				},
			}, () => {
			})
		}
	}


	render() {
		const { professionalTabFormData, professionalTabFormDataError } = this.state;

		return (
			<div className="profilecontainer profilepersonalcon userWrite general_profilepersonalcon">
				<div className="inputviewinner">
					<div className="jbssadn_en_ndassbj">
						<div className={professionalTabFormDataError.emp_id != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Emp Number</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={professionalTabFormData.emp_id}
										onChange={(e) => this.handelChange(e, 'emp_id')}
										readOnly={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>

						<div className={professionalTabFormDataError.working_hours != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Working Hours</div>
							<div className="inputfieldview">
								<ErrorBoundary title="CustomInput Error">
									<CustomInput
										parentClassName="input_field_inner"
										type="text"
										value={professionalTabFormData.working_hours}
										onChange={(e) => this.handelChange(e, 'working_hours')}
										readOnly={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
						<div className={professionalTabFormDataError.joining_date != "" ? "inputrowbox showerror" : "inputrowbox"}>
							<div className="inputlabelview">Joining Date</div>
							<div className="inputfieldview datepickerrow">
								<ErrorBoundary title="CustomInput Error">
									<CommonDateTimePicker
										selected={professionalTabFormData.joining_date}
										onChange={(e) => this.handelChange(e, 'joining_date')}
										showTimeSelect={false}
										dateFormat="dd-MM-yyyy"
										placeholderText="Select date"
										minDate=""
										maxDate=""
										disabled={this.state.disable}
									/>
								</ErrorBoundary>
							</div>
						</div>
					</div>
				</div>
				<div className="rightsideview">
					{this.state.button_enabled &&
						<button type="button" className="submitbtnprofile" onClick={this.professionalfromSubmitData}>Submit</button>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		roleWisePermission: globalState.mainReducerData.roleWisePermission

	};
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, setUserCredentials, logOutApp })
	(withNamespaces()(Professional));