import LoginActionTypes from '../Utility/LoginActionTypes';
export default (state = { "token": "", "accessToken": "", "refreshToken": "", "userGroup": [], "organisationGroup": [], "userEntryType": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, "version": [] }, action) => {


    switch (action.type) {
        //set id token reducer
        case LoginActionTypes.SET_TOKEN:
            return { ...state, "token": action.payload };

        //set access token reducer
        case LoginActionTypes.SET_ACCESS_TOKEN:
            return { ...state, "accessToken": action.payload };

        //set id token expire time reducer
        case LoginActionTypes.SET_TOKEN_EXPIRE_TIME:
            return { ...state, "expiresIn": action.payload };

        //set refresh token reducer
        case LoginActionTypes.SET_REFRESH_TOKEN:
            return { ...state, "refreshToken": action.payload };

        //set user group reducer
        case LoginActionTypes.USER_GROUP:
            return { ...state, "userGroup": action.payload };

        //set organisation group reducer
        case LoginActionTypes.ORGANISATION_GROUP:
            return { ...state, "organisationGroup": action.payload };

        //set selected organisation reducer
        case LoginActionTypes.USER_ENTRY_TYPE:
            return { ...state, "userEntryType": action.payload };

        //set user credential reducer
        case LoginActionTypes.SET_USER_CREDENTIALS:
            return { ...state, "userCredentials": action.payload };

        //set selected organisation version reducer
        case LoginActionTypes.SET_VERSION:
            return { ...state, "version": action.payload };

        //set logout reducer
        case LoginActionTypes.LOGOUT:
            return action.payload;

        //set session id reducer
        case LoginActionTypes.SET_SESSION:
            return { ...state, "session": action.payload };

        default:
            return state;
    }
};
