export const userDetailsGet = (userData) => {
    let resData = {};
    let data = [];
    if (userData.data.success) {
        let userDetailsTempHash = {};
        userDetailsTempHash.user_details = {};

        let userDetailsApiResData = userData.data.data.length > 0 ? userData.data.data[0] : [];
        userDetailsTempHash.user_details.active = userDetailsApiResData.hasOwnProperty('active') && userDetailsApiResData.active ? userDetailsApiResData.active : "";
        userDetailsTempHash.user_details.contact_number = userDetailsApiResData.hasOwnProperty('contact_number') && userDetailsApiResData.contact_number ? userDetailsApiResData.contact_number : "";

        userDetailsTempHash.user_details.email = userDetailsApiResData.hasOwnProperty('email') && userDetailsApiResData.email ? userDetailsApiResData.email : "";
        userDetailsTempHash.user_details.is_verified = userDetailsApiResData.hasOwnProperty('is_verified') && userDetailsApiResData.email ? userDetailsApiResData.is_verified : "";
        userDetailsTempHash.user_details.first_name = userDetailsApiResData.hasOwnProperty('first_name') && userDetailsApiResData.first_name ? userDetailsApiResData.first_name : "";
        userDetailsTempHash.user_details.id = userDetailsApiResData.hasOwnProperty('id') && userDetailsApiResData.id ? userDetailsApiResData.id : "";
        userDetailsTempHash.user_details.last_name = userDetailsApiResData.hasOwnProperty('last_name') && userDetailsApiResData.last_name ? userDetailsApiResData.last_name : "";
        userDetailsTempHash.user_details.profile_img = userDetailsApiResData.hasOwnProperty('profile_img_url') && userDetailsApiResData.profile_img_url ? userDetailsApiResData.profile_img_url : {};
        userDetailsTempHash.user_details.org_id = userDetailsApiResData.hasOwnProperty('UserOrgRelation') && userDetailsApiResData.UserOrgRelation.length > 0 && userDetailsApiResData.UserOrgRelation[0].hasOwnProperty('org_id') ? userDetailsApiResData.UserOrgRelation[0].org_id : "";
        data.push(userDetailsTempHash);
        resData = {
            "success": userData.data.success,
            "total": userData.data.total,
            "data": data
        }
    } else {
        resData = {
            "success": false,
            "message": 'Something went wrong'
        }
    }

    return resData;
}

export const userDeleteGet = (userDeleteData) => {
    return userDeleteData.data
}

export const resetPasswordApi = (data) => {
    return data.data
}

export const usersGet = (usersData) => {
    return usersData.data
}
export const awsVarifiedEmailFun = (usersData) => {
    return usersData.data
}

export const verifyMfaOtpPost = (roledata) => {
    return roledata.data
}
export const verifySoftwareTokenPut = (roledata) => {
    return roledata.data
}
export const mfaAssignPut = (roledata) => {
    return roledata.data
}