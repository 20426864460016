import AllActionTypes from '../Utility/AllActionTypes';

export default (state = { "loaderState": false, "leftbar": false, "activeLink": { 'accName': "", 'activeClass': "", 'lockFlag': true, 'planningEditableDay': 0 }, roleWisePermission: {}, "websocket": {} }, action) => {
    switch (action.type) {
        //app loader true
        case AllActionTypes.LOADER_STATE_TRUE:
            return { ...state, "loaderState": action.payload };
        //app loader false
        case AllActionTypes.LOADER_STATE_FALSE:
            return { ...state, "loaderState": action.payload };
        //app left bar show hide
        case AllActionTypes.HANDLE_LEFT:
            return { ...state, "leftbar": action.payload };
        //active link state change
        case AllActionTypes.ACTIVE_LINK:
            return { ...state, "activeLink": action.payload };
        //role wise permission data
        case AllActionTypes.ROLE_PERMISSION:
            return { ...state, "roleWisePermission": action.payload };
        //websocket connection
        case AllActionTypes.CONNECT_DISCONNECT_TO_WEBSOCKET:
            return { ...state, "websocket": action.payload };
        //lock flag
        case AllActionTypes.LOCK_FLAG:
            return { ...state, "lockFlag": action.payload };
        //planning editable day
        case AllActionTypes.PLANNING_EDITABLE_BEFORE_CURRENT_DAY:
            return { ...state, "planningEditableDay": action.payload };
        //clear data
        case AllActionTypes.CLEAR_DATA:
            return action.payload;
        default:
            return state;
    }
};
