class AllActionTypes {
    //for loader
    static LOADER_STATE_TRUE = 'LOADER_STATE_TRUE';
    static LOADER_STATE_FALSE = 'LOADER_STATE_FALSE';
    static HANDLE_LEFT = 'HANDLE_LEFT';
    static ACTIVE_LINK = 'ACTIVE_LINK';
    static CLEAR_DATA = 'CLEAR_DATA';
    static ROLE_PERMISSION = 'ROLE_PERMISSION';
    static CONNECT_DISCONNECT_TO_WEBSOCKET = 'CONNECT_DISCONNECT_TO_WEBSOCKET';
    static LOCK_FLAG = 'LOCK_FLAG';
    static PLANNING_EDITABLE_BEFORE_CURRENT_DAY = 'PLANNING_EDITABLE_BEFORE_CURRENT_DAY';
}
export default AllActionTypes;