import React from 'react';
import { get, post, put, del } from '../../../Utility/Http';
import { login, currentUser, forcePasswordChangeGet, forgotPasswordGet, changePasswordGet, rolePermissionApi, getVersionListGet, userOrganisationListGet, verifyMfaOtpPost, resendOtpGet, verifyOtpPost, mfaAssignPut, associateSoftwareTokenPut, verifySoftwareTokenPut } from '../Model/LoginModel';

export const loginGetApi = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/login`, data, null);
    return login(response);
};
export const getCurrentUser = async () => {
    const response = await get(`${process.env.EXTENDED_URL}currentuser`);
    return currentUser(response);
};
export const rolePermissionGetApi = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}generic/roles_permissions`, data);
    return rolePermissionApi(response);
};

export const forcePasswordChange = async (data, headers) => {
    const response = await post(`${process.env.EXTENDED_URL}users/userforcepasswordchange`, data, headers);
    return forcePasswordChangeGet(response);
};
export const forgotPassword = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/forgotpassword`, data, null);
    return forgotPasswordGet(response);
};
export const changePassword = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/confirmforgotpassword`, data, null);
    return changePasswordGet(response);
};
export const getVersionList = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}version`, data);
    return getVersionListGet(response);
};
export const userOrganisationGet = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}admin/user_details`, data);
    return userOrganisationListGet(response);
};
export const verifyMfaOtp = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/mfa/verify`, data);
    return verifyMfaOtpPost(response);
};
export const resendOtp = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/resend_confirmation`, data);
    return resendOtpGet(response);
};
export const verifyOtp = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/confirm_signup`, data);
    return verifyOtpPost(response);
};
export const mfaAssign = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/usr_mfa_assign`, data);
    return mfaAssignPut(response);
};
export const associateSoftwareToken = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/associate_software_token`, data);
    return associateSoftwareTokenPut(response);
};
export const verifySoftwareToken = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/verify_software_token`, data);
    return verifySoftwareTokenPut(response);
};
