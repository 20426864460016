class LoginActionTypes {
    static SET_TOKEN = 'SET_TOKEN'; 
    static LOGOUT = 'LOGOUT';
    static SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
    static SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
    static SET_TOKEN_EXPIRE_TIME = 'SET_TOKEN_EXPIRE_TIME';
    static SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
    static SET_VERSION = 'SET_VERSION';   
    static USER_GROUP = 'USER_GROUP';
    static USER_ENTRY_TYPE = 'USER_ENTRY_TYPE';
    static ORGANISATION_GROUP = 'ORGANISATION_GROUP';
    static SET_SESSION = 'SET_SESSION';
}
export default LoginActionTypes;