import React from 'react'
import logger from '../../../logger';


function DemoAggrid() {
    return (
        <div className="instruction">
            <h1>Instructions for a Task</h1>
            <div className="step">
                <h2>Step 1</h2>
                <p>Required Node Version 18.18.0</p>
            </div>
            <div className="step">
                <h2>Step 2</h2>
                <p>Functional Component are mandatory</p>
            </div>
            <div className="step">
                <h2>Step 3</h2>
                <p>All Component will be bind in ErrorHandling</p>
            </div>
            <div className="step">
                <h2>Step 4</h2>
                <p>Do not use console log .</p>
                <p>import logger -- import logger from '../../../logger';</p>
                <p>example --- logger.log('This log message is visible in development');</p>
            </div>

        </div>
    );
};
export default DemoAggrid;
