import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CustomInput from '../Components/CustomInput';
import { connect } from 'react-redux';
import CustomButton from './CustomButton';
import '../Public/css/common.scss';

class ConfirmationAlert extends Component {
    render() {
        const {
            BodyFirstContent, BodySecondContent, BodyThirdContent, BodyForthContent, BodyFifthContent, confirmationButtonContent, cancelButtonContent, remarkInput, t, placeholder, errorLabel, value, onChange, remarkName, saveShow, cancelShow, deleteIconVissable, callFrom, labelName, BodyTableContent, BodyTableContentFlag } = this.props;
        return (
            <div className="modalinnerbody">
                {deleteIconVissable && callFrom == "" &&
                    <div className='deleteIcon'><i className="fa fa-trash" aria-hidden="true"></i></div>
                }
                {!deleteIconVissable && callFrom == "booking_conversion" &&
                    <div className='convertIcon'><i class="fa fa-exchange" aria-hidden="true"></i></div>
                }
                {remarkInput ?
                    <div className="remarkinputinner">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName={labelName}
                            errorLabel={errorLabel}
                            name={remarkName}
                            type="textarea"
                            value={value}
                            labelPresent={true}
                            placeholder={placeholder}
                            onChange={onChange}
                        />
                    </div> : null}
                {!BodyTableContentFlag ?

                    <p>{BodyFirstContent} <span>{BodySecondContent}</span> {BodyThirdContent} {BodyForthContent} {BodyFifthContent}</p>
                    : null

                }

                {BodyTableContentFlag ? BodyTableContent : null}

                {!BodyTableContentFlag ?
                <div className="col-md-12 modfooterbtn">
                    {saveShow ? <>
                        <CustomButton
                            onClick={this.props.deleteConfirmButton}
                            className="savebtn"
                            children={confirmationButtonContent}
                        />
                    </> : null
                    }
                    {cancelShow ?
                        <>
                            <CustomButton
                                onClick={this.props.deleteCancleButton}
                                className="cancelbtn"
                                children={cancelButtonContent}
                            />
                        </> : null
                    }
                </div>:null}
            </div>
        );
    }
}


ConfirmationAlert.defaultProps = {
    BodyFirstContent: "",
    BodySecondContent: "",
    BodyThirdContent: "",
    BodyForthContent: "",
    BodyFifthContent: "",
    confirmationButtonContent: "",
    cancelButtonContent: "",
    remarkInput: false,
    placeholder: "",
    remarkName: "deleteRemarkName",
    saveShow: true,
    cancelShow: true,
    deleteIconVissable: false,
    callFrom: "",
    labelName: "",
    BodyTableContent: "",
    BodyTableContentFlag: false
}

const mapStateToProps = (globalState) => {
    return {

    };
}

export default connect(mapStateToProps, {})(ConfirmationAlert);

