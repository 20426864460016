import React from 'react';
import { get, post, put, del, patch } from '../Http';
import { changePasswordGet, userNotificationsGet, userNotificationsPatch, userChangePasswordApi, userDetailsGet, orgListingGet, showNotificationGet, bookingsIndividualDetailsGet, checkLiveOrNotGet, branchListGet, branchWiseRoomTypeApiGet, calenderReportGet } from '../Model/UtilityModel';

import * as Store from '../../store'
let version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";

export const changePassword = async (data) => {
    const response = await patch(`${process.env.EXTENDED_URL}users/changepassword`, data, { "Accesstoken": "", "Authorization": "" });
    return changePasswordGet(response);
};

export const notifications = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}notification`, data);
    return userNotificationsGet(response);
};

export const notificationsUserId = (data) => {
    return patch(`${process.env.EXTENDED_URL}notification`, data).then((response) => {
        return userNotificationsPatch(response)
    });
};
export const userNewPasswordChange = async (data) => {
    const response = await patch(`${process.env.EXTENDED_URL}users/changepassword`, data, { passwordChange: true });
    return userChangePasswordApi(response);

};

export const user_Details = async (id, data) => {
    const response = await get(`${process.env.EXTENDED_URL}admin/${id}`, data);
    return userDetailsGet(response);
};

export const orgListing = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}organisation`, data);
    return orgListingGet(response);
};

export const showNotification = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}notification`, data);
    return showNotificationGet(response);
};

export const bookingsIndividualDetails = async (id, data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/bookings/individual/${id}`, data);
    return bookingsIndividualDetailsGet(response);
};

export const checkLiveOrNot = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}check/live`, data);
    return checkLiveOrNotGet(response);
};
export const branchList = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_branch/branch_list`, data);
    return branchListGet(response);
};
export const branchWiseRoomTypeApi = async (data) => {
    version = Object.keys(Store.default.getState().LoginReducer.userCredentials).length > 0 ? Store.default.getState().LoginReducer.userCredentials.user_details.version : "";
    const response = await get(`${process.env.EXTENDED_URL}${version}/hotel_room_types`, data);
    return branchWiseRoomTypeApiGet(response);
};
export const calenderReport = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}reports/occupancy_report`, data);
    return calenderReportGet(response);
};