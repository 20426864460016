import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../Utility/Components/Header'
import Footer from '../Utility/Components/Footer'
import Left from '../Utility/Components/Left'
import { logOutApp } from '../GlobalModules/Login/Actions/LoginAction';
import NotFoundPage from '../Utility/Pages/NotFound';


const AuthLayout = (props) => {
  const { userCredentials, logOutApp, history, allowedRoles } = props;
  const [showNotFound, setShowNotFound] = useState(false);

  //initially check user credentials are valid or not
  useEffect(() => {
    checkForUSerCrendentials()
  }, []);

  //user credentials checking and redirection fn
  const checkForUSerCrendentials = () => {
    if (props.token == undefined || props.token == "") {
      history.push("/")
    }
    if (!props.token || props.token === "" || !allowedRoles.includes(userCredentials.user_details.user_type)) {
      const timer = setTimeout(() => {
        setShowNotFound(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }

  return (
    <>
      {props.token && props.token !== "" && allowedRoles.includes(userCredentials.user_details.user_type) ?
        <div className="page_customize">
          <div className="pagecontainer">
            <Header history={props.history} />
            <Left history={props.history} />
            <div className={props.leftbar ? 'content_open' : 'content_closed'} style={{ minHeight: '91vh', backgroundColor: 'rgb(248, 248, 248)', paddingTop: '0px', transition: 'padding .3s ease-in-out', display: 'flex', borderRadius: '0px 0px 15px 65px' }}>
              {props.children}
            </div>
            {userCredentials?.user_details ? <Footer /> : null}
          </div>
        </div>
        :
        showNotFound && <NotFoundPage permission={false} history={props.history} />
      }
    </>
  );

}


const mapStateToProps = (globalState) => {
  return {
    leftbar: globalState.mainReducerData.leftbar,
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token,
  };

}

export default connect(mapStateToProps, { logOutApp })(AuthLayout);