import React, { useEffect } from 'react';
import Select, { components } from "react-select";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Menu = (props) => {
    return (
        <>
            <components.Menu {...props}>
                <div className="dropdowninnersection">
                    <div className="dropdownlabelbtn">
                        <div className="dropheaderlabel">{props.selectProps.menuHeader}</div>
                        <button className="dropheaderbtn" onClick={props.selectProps.oncloseMenu}>
                            <img src={require('../Public/images/dropcloseicon.png')} />
                        </button>
                    </div>
                    <div>{props.children}</div>
                </div>
            </components.Menu>
        </>
    );
};

const CustomOption = (props) => {
    return (
        <>
            <components.Option {...props}>
                {props.data.tooltipShow ?
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data.label}</Tooltip>}>
                        <span>{props.children}</span>
                    </OverlayTrigger>
                    : props.children
                }
            </components.Option>
        </>
    )
};

const CustomSingleValue = (props) => {
    return (
        <>
            <components.SingleValue {...props}>
                {props.data.tooltipShow ?
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.data.label}</Tooltip>}>
                        <span>{props.children}</span>
                    </OverlayTrigger>
                    : props.children
                }
            </components.SingleValue>
        </>
    );
}

const AutosuggestComponent = (props) => {

    const componentDidMount = () => {
    }
    const oncloseMenu = () => {
        // alert()
        //console.log("close==>",selectRef)
        selectRef.select.blur()
    }
    let selectRef = null
    const { closeButton, menuHeader, openMenu } = props
    return (

        <div>
            {closeButton == false ?
                <Select
                    value={props.selectedValue}
                    isClearable={props.isClearable}
                    isSearchable={props.isSearchable}
                    options={props.options}
                    name={props.name}
                    //isLoading={formLoading}
                    isMulti={props.isMulti}
                    onChange={props.handleOnChange}
                    onInputChange={props.handleOnInputChange}
                    onBlur={props.handleOnBlurChange}
                    placeholder={props.placeholder}
                    isDisabled={props.isDisabled}
                    className="basic-select"
                    //defaultMenuIsOpen={true}
                    //checkbox={true}
                    id={props.id}
                    //ref={props.selectRef}
                    menuPosition={props.menuPosition}
                    classNamePrefix={props.classNamePrefix}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                /> :
                <Select
                    value={props.selectedValue}
                    isClearable={props.isClearable}
                    isSearchable={props.isSearchable}
                    options={props.options}
                    name={props.name}
                    //isLoading={formLoading}
                    isMulti={props.isMulti}
                    onChange={props.handleOnChange}
                    onInputChange={props.handleOnInputChange}
                    onBlur={props.handleOnBlurChange}
                    placeholder={props.placeholder}
                    isDisabled={props.isDisabled}
                    className="basic-select"
                    components={{
                        Menu
                    }}
                    menuHeader={menuHeader}
                    oncloseMenu={oncloseMenu}
                    // defaultMenuIsOpen={true}
                    //checkbox={true}
                    ref={(scope) => { selectRef = scope }}
                    classNamePrefix={props.classNamePrefix}
                />}
        </div>
    );

}
AutosuggestComponent.defaultProps = {
    isClearable: false,
    closeButton: false,
    menuHeader: "",
    openMenu: true,
    isSearchable: false,
    id: "",
    //selectRef:null,
    menuPosition: "",
    classNamePrefix: "common-dropdown-box"
}
export default AutosuggestComponent;
