import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../Login/Actions/LoginAction';
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import '../../UserProfile/Assets/css/profiledoc.scss';
import * as ProfileController from '../Controller/ProfileController';
import Utility from '../../../Utility/Utility';
const lodash = require('lodash')
import ConfirmationAlert from '../../../Utility/Components/ConfirmationAlert';
import ModalGlobal from '../../../Utility/Components/ModalGlobal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Personal from '../Components/Personal/Personal';
import Professional from '../Components/Professional/Professional';
import ImageCropContent from '../../../Utility/Components/ImageCropContent'
import { getCurrentUser } from '../../Login/Controller/LoginController';
import ErrorBoundary from '../../../Utility/Components/ErrorBoundary';
import { withNamespaces } from 'react-i18next';
import SmsVerification from '../../Login/Components/SmsVerification';


class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTabName: "personal",
			deleteConfirmationModal: false,
			archivingDateModal: false,
			//Set data
			userName: "",
			phoneNumber: "",
			userEmail: "",
			userStatus: "",
			is_verified: false,

			renderChildTabs: false,
			tabChangeConfirmModal: false,
			tabchangeInner: false,
			tempTabName: "",
			backToWorkerConfirmed: false,

			confirmationAlertModal: false,
			resetconfirmationAlertModal: false,
			//worker profile

			profileImagePreviewShow: false,
			profileImage: require('../../../Utility/Public/images/blank_img.png'),
			workerFormData: {
				profileImage: {
					"file_name": "",
					"file_obj": ""
				}
			},
			profileImageError: "",
			userRoleName: "",
			existingProfileImage: require('../../../Utility/Public/images/blank_img.png'),
			//IMage crop
			src: null,
			crop: {
				unit: '%',
				width: 30,
			},
			croppedImageUrl: "",

			imageCropModalFlag: false,
			hasLogin: false,
			isCognitoUser: false,
			user_response_profile_img_data: "",
			image_upload_success: false,
			emp_id: "",
			isLoading: true,
			personalTabPermission: [],
			professionalTabPermission: [],

			//mfa
			mfaAlertModal: false,
		}
		this.profileContentRef = React.createRef(true);
	}

	componentDidMount() {
		this.userDetails();
		this.permissionWiseTabSelected();
		this.fetchTabData();
	}


	detectChangesIndividualInnerTab = (value = false) => {
		this.setState({
			tabchangeInner: value
		})

	}

	//get user details api fn
	userDetails = () => {
		const { loaderStateTrue, loaderStateFalse, userProfileId } = this.props;
		loaderStateTrue();
		ProfileController.user_Details(userProfileId).then((response) => {
			if (response.success) {
				if (response.data.length > 0) {
					this.setUserDetails(response.data[0]);
				}
				this.setState({
					user_response_profile_img_data: response.data[0].user_details,
				})
			}
			loaderStateFalse();
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
			if (error.message == "Network Error") {

			}
		});
	}

	//set user details fn
	setUserDetails = (obj) => {
		let dataSet = obj.user_details;
		let profileImage = Object.keys(dataSet.profile_img).length > 0 ? JSON.parse(dataSet.profile_img) : "";
		this.setState({
			userName: Utility.displayNameFormat(dataSet.first_name, dataSet.last_name),
			phoneNumber: dataSet.contact_number,
			profileImage: profileImage != "" ? profileImage.img_url : require('../../../Utility/Public/images/blank_img.png'),
			userEmail: dataSet.email,
			is_verified: dataSet.is_verified,
			userStatus: dataSet.is_active,
			existingProfileImage: profileImage != "" ? profileImage.img_url : require('../../../Utility/Public/images/blank_img.png')
		}, () => {
		})
	}

	//personal , professional tab change confirmation modal open fn
	openTabChangeConfirmModal = () => {
		this.setState({
			tabChangeConfirmModal: true
		})
	}

	//personal , professional tab change confirmation modal close fn
	tabChangeConfirmModalHide = () => {
		this.setState({
			tabChangeConfirmModal: false,
			tempTabName: ""
		})
	}

	//tab change on change fn
	handelSelectTab = (tabName) => {
		if (this.state.tabchangeInner == true) {
			this.setState({
				tempTabName: tabName
			}, () => {
				this.openTabChangeConfirmModal()
				this.confirmTabChange()
			})
		} else {
			this.setState({
				selectedTabName: tabName
			})
		}
	}

	//confirm tab change fn
	confirmTabChange = () => {
		if (this.state.backToWorkerConfirmed == false) {
			this.setState({
				selectedTabName: this.state.tempTabName
			}, () => {
				this.cancelTabChange()
				this.setState({
					tabchangeInner: false
				})
			})
		} else {
			//for back button
			this.tabChangeConfirmModalHide()
			this.props.detectChangesIndividualTab(false)
			this.detectChangesIndividualInnerTab(false)
			this.props.backToWorker()
		}
	}

	//cancel tab change fn
	cancelTabChange = () => {
		this.tabChangeConfirmModalHide()
	}

	//back to user profile section
	backToWorker = () => {
		this.setState({
			backToWorkerConfirmed: true
		}, () => {
			if (this.state.tabchangeInner == true) {
				this.openTabChangeConfirmModal()
			} else {
				this.props.detectChangesIndividualTab(false)
				this.detectChangesIndividualInnerTab(false)
				this.props.backToWorker()
			}
		})


	}

	//select the frst tab first
	permissionWiseTabSelected = () => {
		const { roleWisePermission } = this.props;
		this.setState({
			selectedTabName: "personal"
		})
	}

	//phone number change callback success fn
	phoneNumberUpdateSuccessFn = (data) => {
		if (Object.keys(data).length > 0) {
			if (data.hasOwnProperty('contact') && data.contact != null) {
				let contact = data.contact;
				if (!contact.includes('+') && contact != "") {
					contact = '+' + contact;
				}
				this.setState({
					phoneNumber: contact
				})
			}
		}
	}

	//permission wise tab selection fn
	async fetchTabData() {
		const personalTabPermission = await Utility.getRoleObjectBySubEventKey('user', 'personal');
		const professionalTabPermission = await Utility.getRoleObjectBySubEventKey('user', 'professional');
		this.setState({
			personalTabPermission,
			professionalTabPermission
		}, () => {
			if (this.state.personalTabPermission.length == 0 && this.state.professionalTabPermission.length > 0) {
				this.setState({
					selectedTabName: "professional"
				})
			}
		});
	}

	tabUi() {
		const { personalTabPermission, professionalTabPermission } = this.state;

		const tabsToRender = [];

		if (personalTabPermission.length > 0 || this.props.userCredentials.user_details?.user_type === "app_admin") {
			tabsToRender.push(
				<Tab eventKey="personal" title="Personal" key={0}>
					<ErrorBoundary title="Personal Tab Error">
						<Personal
							userProfileId={this.props.userProfileId}
							selectedTabName={this.state.selectedTabName}
							detectChangesIndividualTab={this.props.detectChangesIndividualTab}
							detectChangesIndividualInnerTab={this.detectChangesIndividualInnerTab}
							renderChildTabs={this.state.renderChildTabs}
							userStatus={this.state.userStatus}
							tabchangeInner={this.state.tabchangeInner}
							userEmail={this.state.userEmail}
							phoneNumberUpdateSuccessFn={this.phoneNumberUpdateSuccessFn}
						/>
					</ErrorBoundary>
				</Tab>
			);
		}

		if (professionalTabPermission.length > 0 && this.props.userCredentials.user_details?.user_type !== "app_admin") {
			tabsToRender.push(
				<Tab eventKey="professional" title="Professional" key={1}>
					{this.state.selectedTabName === 'professional' ?
						<ErrorBoundary title="Professional Tab Error">
							<Professional
								userProfileId={this.props.userProfileId}
								selectedTabName={this.state.selectedTabName}
								detectChangesIndividualTab={this.props.detectChangesIndividualTab}
								detectChangesIndividualInnerTab={this.detectChangesIndividualInnerTab}
								renderChildTabs={this.state.renderChildTabs}
								userStatus={this.state.userStatus}
								tabchangeInner={this.state.tabchangeInner}
								userEmail={this.state.userEmail}
							/>
						</ErrorBoundary>
						: null}
				</Tab>
			);
		}

		return tabsToRender;
	}

	//profile image ui section
	profileImageUi = () => {
		const { roleWisePermission } = this.props;
		const { user_response_profile_img_data, image_upload_success } = this.state;
		let roleName = this.props.userCredentials.hasOwnProperty('user_details') && this.props.userCredentials.user_details && this.props.userCredentials.user_details.hasOwnProperty('role_name') && this.props.userCredentials.user_details.role_name;
		let uiView = <></>;
		uiView = <label className="custom-file-upload">
			{user_response_profile_img_data.profile_img_url && user_response_profile_img_data.hasOwnProperty('profile_img_url') ?
				<img src={this.state.profileImage} /> :
				<div className="user_name_box_profile"><img src={this.state.profileImage} />
					{user_response_profile_img_data.profile_img_url && Object.keys(user_response_profile_img_data.profile_img_url).length == 0 ?
						<div className="user_name_label">{user_response_profile_img_data.first_name && user_response_profile_img_data.first_name.charAt(0)}{user_response_profile_img_data.last_name && user_response_profile_img_data.last_name.charAt(0)}</div> : null}
				</div>}
			{roleName == "app_admin" || this.props.userProfileId == this.props.userCredentials.id ?
				<input type="file" accept={Utility.acceptFileType()} onChange={this.profileImageChanged} /> : null}
			{roleName == "app_admin" || this.props.userProfileId == this.props.userCredentials.id ?
				<div className="hoverlayerbackground">
					<div className="hoverContent">
						<img src={require('../../../Utility/Public/images/cameraicon.png')} className="cameraicon" />
						<p>{this.props.t('uploadphoto')}</p>
					</div>
				</div>
				: null}
		</label>;
		return uiView;
	}

	//profile image upload onchange fn
	profileImageChanged = (event) => {
		this.props.detectChangesIndividualTab(true)
		let { workerFormData } = this.state;
		let targetFileSplit = event.target.files[0].name.split('.');
		let lastElement = targetFileSplit.pop();
		let user_profile_image = {
			"file_name": "",
			"file_obj": ""
		};
		const file = event.target.files[0];
		const allowedTypes = Utility.acceptFileType();
		Utility.checkFileType(file, allowedTypes)
			.then(results => {
				if (results.isValid) {
					const fsize = event.target.files[0].size;
					const fileSize = Math.round((fsize / 1024));
					if (fileSize >= 300) {
						Utility.toastNotifications(props.t('imageUploadAlert'), "Warning", "warning");
					} else {
						this.setState({
							imageCropModalFlag: true
						})

						if (event.target.files && event.target.files.length > 0) {
							const reader = new FileReader();
							reader.addEventListener('load', () =>
								this.setState({ src: reader.result })
							);
							reader.readAsDataURL(event.target.files[0]);
							user_profile_image["file_name"] = event.target.files[0].name
							user_profile_image["file_obj"] = ""
							workerFormData["profileImage"] = user_profile_image
							this.setState({
								workerFormData,
								profileImagePreviewShow: true,
								profileImageError: "",
								tabchangeInner: true
							}, () => {
							})
						}
					}
				} else {
					workerFormData["profileImage"] = {}
					this.setState({
						profileImageError: "Required field",
						profileImagePreviewShow: false,
						workerFormData,
						profileImage: 'Add Image'
					})
					Utility.toastNotifications("File format is not supported, it should be in jpg, jpeg or png format", "Warning", "warning");
				}
			})
			.catch(error => {
				console.error("Error:", error);

			});
	}

	//get base64 from uploaded file
	getBase64(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
		};
	}

	//formatting user data
	formatWorkerData = () => {
		let { workerFormData } = this.state
		let user_details = {};
		user_details['profile_img'] = workerFormData.profileImage;
		return user_details
	}

	//submit user profile information fn
	submitWorkerProfile = () => {
		const { loaderStateFalse, loaderStateTrue, userProfileId } = this.props;
		let valid = true;
		if (Object.keys(this.state.workerFormData.profileImage).length == 0) {
			valid = false;
			Utility.toastNotifications("Profile image cannot be blank", "Error", "error")
		}
		if (valid) {
			let dataset = this.formatWorkerData();
			loaderStateTrue();
			ProfileController.users(dataset, userProfileId).then((response) => {
				loaderStateFalse();
				if (response.success) {
					this.setState({
						tabchangeInner: false,
						image_upload_success: false
					})
					if (userProfileId == this.props.userCredentials.id) {
						this.getCurrentUser();
					}
					Utility.toastNotifications(response.message, "Success", "success");
					this.cancelWorkerProfile();
				} else {
					Utility.toastNotifications(response.message, "Error", "error")
				}
			}).catch((error) => {
				console.error("************error*************", error)
				if (error) {
				}
				loaderStateFalse();
				if (error.message == "Network Error") {

				}
			});
		}
	}

	//get current user data fn
	getCurrentUser = () => {
		const { loaderStateTrue, loaderStateFalse, setUserCredentials } = this.props;
		getCurrentUser().then((response) => {
			if (response.success) {
				setUserCredentials(response.data)
			}
		}).catch((error) => {
			console.error("************error*************", error)
			loaderStateFalse();
		});
	}

	//cancel user profile changes fn
	cancelWorkerProfile = () => {
		this.setState({
			profileImagePreviewShow: false,
			image_upload_success: false
		})
		this.props.detectChangesIndividualTab(false)
		this.userDetails();
	}

	//reset password confirmation modal confirm fn
	resetdeleteConfirmButton = () => {
		this.resetPasswordApi();
	}

	//reset password confirmation modal cancel fn
	resetdeleteCancleButton = () => {
		this.resetconfirmationModalHide();
	}


	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	onCropComplete = (crop) => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		const { workerFormData } = this.state;
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'newFile.jpeg'
			);
			const base64Data = croppedImageUrl.replace(/^data:[^,]+,/, '');
			const decodedSize = (base64Data.length * 3) / 4;
			if (decodedSize <= 300 * 1024) {
				let user_profile_image = {}
				this.setState({ profileImage: croppedImageUrl }, () => {
					user_profile_image["file_name"] = this.state.workerFormData.profileImage.file_name
					user_profile_image["file_obj"] = this.state.profileImage
					workerFormData["profileImage"] = user_profile_image
					this.setState({
						workerFormData
					})
				});
			} else {
				Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
			}
		}
	}

	getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);

		const base64Image = canvas.toDataURL('image/jpeg');
		return base64Image
	}

	//Image url crop

	imageCropModalShow = () => {
		this.setState({
			imageCropModalFlag: true
		})
	}

	imageCropModalHide = () => {
		const { workerFormData, existingProfileImage } = this.state;
		workerFormData["profileImage"] = existingProfileImage
		this.setState({
			imageCropModalFlag: false,
			profileImage: existingProfileImage,
			profileImageError: "",
			workerFormData,
			profileImagePreviewShow: false,
			tabchangeInner: false
		})

	}

	imageCropDataSave = () => {
		this.setState({
			imageCropModalFlag: false,
			image_upload_success: true
		})
	}


	//Delete User
	deleteUser = () => {
		this.setState({
			confirmationAlertModal: true
		})
	}

	confirmationModalHide = () => {
		this.setState({
			confirmationAlertModal: false
		})
	}

	usersDeleteSuccess = () => {
		const { loaderStateTrue, loaderStateFalse, userProfileId } = this.props;
		loaderStateTrue();
		let data = {}
		data['id'] = [userProfileId];
		ProfileController.userDelete(data).then((response) => {
			if (response[0].data[0].success) {
				this.setState({
					confirmationAlertModal: false
				}, () => {
					this.backToWorker();
				})
				Utility.toastNotifications(response[0].data[0].message, "Success", "success");
			} else {
				Utility.toastNotifications(response[0].data[0].message, "Error", "error");
			}
			loaderStateFalse();
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
			if (error.message == "Network Error") {

			}
		});
	}

	deleteCancleButton = () => {
		this.confirmationModalHide();
	}

	resetPassword = () => {
		this.setState({
			resetconfirmationAlertModal: true
		})

	}

	resetconfirmationModalHide = () => {
		this.setState({
			resetconfirmationAlertModal: false
		})
	}

	resetdeleteConfirmButton = () => {
		this.resetPasswordApi();
	}

	resetdeleteCancleButton = () => {
		this.resetconfirmationModalHide();
	}

	resetPasswordApi = () => {
		const { loaderStateTrue, loaderStateFalse } = this.props;
		const { userEmail, phoneNumber } = this.state;
		let data = {};
		if (userEmail.trim() != "") {
			data = { "username": userEmail.trim() }
		}
		if (userEmail == "") {
			data = { "username": phoneNumber }
		}
		loaderStateTrue()
		ProfileController.resetPassword(data).then((response) => {
			if (response) {
				if (response.success) {
					this.resetconfirmationModalHide();
					Utility.toastNotifications(response.message, "Success", "success");
				} else {
					Utility.toastNotifications(response.message, "Error", "error");
				}
				loaderStateFalse();
			}
		}).catch((error) => {
			console.error("************error*************", error)
			if (error) {
			}
			loaderStateFalse();
			if (error.message == "Network Error") {

			}
		});
	}

	//email verify api fn
	varifiedEmail = (e, email, callFrom) => {
		const { loaderStateTrue, loaderStateFalse } = this.props;
		loaderStateTrue();
		let data = {}
		data['callFrom'] = callFrom
		data['email'] = email
		ProfileController.awsVarifiedEmail(data).then((response) => {
			if (response.success) {
				this.userDetails();
				Utility.toastNotifications(response.message, "Success", "success")
			} else {
				Utility.toastNotifications(response.message, "Error", "error")
			}
			loaderStateFalse();
		}).catch((error) => {
			Utility.toastNotifications(error?.response?.message, "Warning", "warning")
			loaderStateFalse();
		});
	}

	//mfa confirmation modal cancel fn
	mfaConfirmationModalHide = () => {
		this.setState({
			mfaAlertModal: false
		})
	}

	//mfa modal open fn
	enableMfaFn = () => {
		this.setState({
			mfaAlertModal: true
		})
	}

	//mfa modal : otp verification submit fn
	handleSubmitOtpVerfication = (otp, type) => {
		const { loaderStateTrue, loaderStateFalse } = this.props;
		if (type === 'authenticator') {
			loaderStateTrue();
			let data = {
				"mfaCode": otp.join(''),
			}
			ProfileController.verifySoftwareToken(data).then((response) => {
				if (response.success) {
					const mfaAssignData = {
						"mfa_type": "authenticator_app",
						"user_name": this.state.userEmail.trim()
					}
					ProfileController.mfaAssign(mfaAssignData).then((response) => {
						if (response.success) {
							this.setState({
								mfaAlertModal: false,
							})
							this.getCurrentUser();
						} else {
							Utility.toastNotifications(response.message, 'Error', 'error');
						}
						loaderStateFalse();
					}).catch((error) => {
						loaderStateFalse();

					});
				} else {
					Utility.toastNotifications(response.message, "Error", "error")
				}
				loaderStateFalse();
			}).catch((error) => {
				console.error("************error*************", error)
				if (error) {
					Utility.toastNotifications(error?.response?.message, "Warning", "warning")
				}
				loaderStateFalse();
			});
		}
	}

	//mfa modal close fn
	closeMfaModalFn = (two_factor_login = null) => {
		this.setState({
			mfaAlertModal: false
		})
	}

	render() {
		const { t, profilecontentdispaly, selectedRowsData, paramsData } = this.props;
		const userType = selectedRowsData.user_type;
		const userTypeStringWithSpaces = userType.replace(/_/g, " ");

		return (
			<div className={`${profilecontentdispaly} profilemaincontainer`}>
				<div className={this.state.userStatus ? 'profilemaincontainerinner' : " profilemaincontainerinnerdisabled profilemaincontainerinner"}>
					<div className="profiletopbox">
						<div className="col-md-12">

							<div className="row">
								<div className="col-md-9">
									<div className={`${this.state.profileImagePreviewShow ? "profileimgsec_top_space" : ""} profileimgsec`}>
										<button onClick={this.backToWorker} className="backtoworker"><i className="fa fa-angle-left" aria-hidden="true"></i></button>
										<div className='user_add_img userprofileimg desarchiverEmployee'>
											{this.profileImageUi()}
											<>{this.state.profileImagePreviewShow == true ? <div className="savecancelinner"><button onClick={this.submitWorkerProfile} className="profileimgsave">Save</button><button onClick={this.cancelWorkerProfile} className="profileimgcancel">Cancel</button></div> : <></>}</>
											<div className="errorClass error_div">{this.state.profileImageError}</div>
										</div>
										<div className="userprofdetails">
											<h3 className="pronamelabel">
												{
													this.state.userName != null && this.state.userName.length > 22 ?
														<OverlayTrigger overlay={<Tooltip>{this.state.userName}</Tooltip>}>
															<span>{this.state.userName}</span>
														</OverlayTrigger>
														: <span>{`${this.state.userName} ( ${userTypeStringWithSpaces} )`}</span>
												}
											</h3>
											<p className="pronumlabel emailidlabel">{this.state.userEmail}
												{this.state.is_verified ?
													<div class="tooltipCustom">
														<div className='varifiedBox'> <img src={require('../../../Utility/Public/images/varified.png')} alt='' className='varified' /></div>
														<span class="tooltipCustomtext smallBox">Verified</span>
													</div> :

													this.props.userCredentials.hasOwnProperty('user_details') && this.props.userCredentials.user_details.user_type != 'customer' ?
														<div class="tooltipCustom">
															<button className='ellipsis-btn varify-btn' onClick={(e) => this.varifiedEmail(e, this.state.userEmail, 'User')} >
																Verify</button>
															<span class="tooltipCustomtext">
																To start sending notification emails, just click 'Verify' below.
																Check your email for a verification link. Click it to confirm.
																Once done, you're ready to send emails!
															</span>
														</div>
														:
														<div class="tooltipCustom">
															<div className='varifiedBox unvarifiedBox'> <img src={require('../../../Utility/Public/images/not-verified.png')} alt='' className='varified' /></div>
															<span class="tooltipCustomtext smallBox">Not Verified</span>
														</div>
												}
											</p>
											<p className="pronumlabel phonenumber">{this.state.phoneNumber}</p>
											{this.props.profileView &&
												<span className='userprofileEnableMFABtn'
													onClick={this.enableMfaFn}
												>{this.props.mfa == null ? 'Enable MFA ?' : 'Change MFA ?'}</span>
											}
										</div>
									</div>
								</div>
								<ErrorBoundary title="ModalGlobal Error">
									<ModalGlobal
										show={this.state.imageCropModalFlag}
										onHide={this.imageCropModalHide}
										onCancel={this.imageCropModalHide}
										onSave={this.imageCropDataSave}
										className="modalcustomize cropmodalcontent"
										bodyClassName="cropmodalcontentbody"
										headerclassName="close_btn_icon"
										title={t('cropimage')}
										footer={true}
										closeButton={true}
										saveButtonLabel={t('crop')}
										saveButtonClassName="delconfirmbtn btn btn-primary"
										cancelButtonClassName="delcancelbtn btn btn-primary"
										body={
											<>
												<ErrorBoundary title="ImageCropContent Error">
													<ImageCropContent
														onImageLoaded={this.onImageLoaded}
														onComplete={this.onCropComplete}
														onCropChange={this.onCropChange}
														crop={this.state.crop}
														croppedImageUrl={this.state.croppedImageUrl}
														src={this.state.src}
														onCropComplete={this.onCropComplete}
														imageCropModalShow={this.imageCropModalShow}
													/>
												</ErrorBoundary>
											</>
										}
									/>
								</ErrorBoundary>
							</div>
						</div>
					</div>
					<div className="profiletabsview">
						<Tabs
							id="homepagetabs"
							activeKey={this.state.selectedTabName}
							className="tabsmainbox"
							onSelect={this.handelSelectTab}
						>
							{this.tabUi()}
						</Tabs>

					</div>
					<ErrorBoundary title="ModalGlobal Error">
						<ModalGlobal
							show={this.state.tabChangeConfirmModal}
							onHide={this.tabChangeConfirmModalHide}
							className="modalcustomize confirmationalertmodal"
							bodyClassName="cancelConfirmationbody"
							headerclassName="close_btn_icon"
							title=""
							footer={false}
							body={
								<>
									<ErrorBoundary title="ConfirmationAlert Error">
										<ConfirmationAlert
											BodyFirstContent={t('navigateConfirmation')}
											BodySecondContent=""
											BodyThirdContent=""
											confirmationButtonContent={t('confirm')}
											cancelButtonContent={t('cancel')}
											deleteConfirmButton={this.confirmTabChange}
											deleteCancleButton={this.cancelTabChange}
										/>
									</ErrorBoundary>
								</>
							}
						/>
					</ErrorBoundary>
					<ErrorBoundary title="ModalGlobal Error">
						<ModalGlobal
							show={this.state.confirmationAlertModal}
							onHide={this.confirmationModalHide}
							className="modalcustomize confirmationalertmodal"
							bodyClassName="cancelConfirmationbody"
							headerclassName="close_btn_icon"
							title="Delete This Person?"
							footer={false}
							body={
								<>
									<ErrorBoundary title="ConfirmationAlert Error">
										<ConfirmationAlert
											BodyFirstContent={`Are you sure you want to delete ${this.state.userName}  permanently? You won't be able to go back.`}
											confirmationButtonContent="Confirm"
											cancelButtonContent="Cancel"
											deleteConfirmButton={this.usersDeleteSuccess}
											deleteCancleButton={this.deleteCancleButton}
										/>
									</ErrorBoundary>
								</>
							}
						/>
					</ErrorBoundary>
					<ErrorBoundary title="ModalGlobal Error">
						<ModalGlobal
							show={this.state.resetconfirmationAlertModal}
							onHide={this.resetconfirmationModalHide}
							className="modalcustomize confirmationalertmodal"
							bodyClassName="cancelConfirmationbody"
							headerclassName="close_btn_icon"
							title="Reset User Password?"
							footer={false}
							body={
								<>
									<ErrorBoundary title="ConfirmationAlert Error">
										<ConfirmationAlert
											BodyFirstContent="Are you sure you want to reset"
											BodySecondContent={
												this.state.userName.length >= 20 ? this.state.userName.substring(0, 20) + "..." : this.state.userName}
											BodyThirdContent="password? You won't be able to go back."
											confirmationButtonContent="Confirm"
											cancelButtonContent='Cancel'
											deleteConfirmButton={this.resetdeleteConfirmButton}
											deleteCancleButton={this.resetdeleteCancleButton}
										/>
									</ErrorBoundary>
								</>
							}
						/>
					</ErrorBoundary>
					<ErrorBoundary title="ModalGlobal Error">
						<ModalGlobal
							show={this.state.mfaAlertModal}
							onHide={this.mfaConfirmationModalHide}
							title=""
							className="modalcustomize mondimension smsVerificationModalContent userprofileSmsVerificationModal"
							footer={false}
							closeButton={true}
							saveButtonLabel="Confirm"
							saveButtonClassName="btn btn-primary savebtn"
							cancelShow={true}
							headerShow={true}
							body={
								<>
									<ErrorBoundary title="ConfirmationAlert Error">
										<SmsVerification
											formData={{ 'email': this.state.userEmail.trim() }}
											callFrom={this.props.mfa == null ? '' : this.props.mfa}
											handleSubmitOtpVerfication={this.handleSubmitOtpVerfication}
											enableMfa={false}
											closeMfaModalFn={this.closeMfaModalFn}
											disableAction={this.props.userGroup.some(user => user.is_mfa_required === true)}
										/>
									</ErrorBoundary>
								</>
							}
						/>
					</ErrorBoundary>
				</div>
			</div>
		);
	}
}

UserProfile.defaultProps = {
	profileView: false
}

const mapStateToProps = (globalState) => {
	return {
		userCredentials: globalState.LoginReducer.userCredentials,
		token: globalState.mainReducerData.token,
		access_token: globalState.mainReducerData.access_token,
		roleWisePermission: globalState.mainReducerData.roleWisePermission,
		mfa: globalState.LoginReducer.userCredentials.user_details.mfa,
		userGroup: globalState.LoginReducer.userGroup
	};
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setToken, setUserCredentials, logOutApp })
	(withNamespaces()(UserProfile));