export const login = (loginData) => {
    let response = loginData.data;
    let loginResData = {};
    if (response) {
        if (response.success) {
            //first time new password set
            if (response.data.hasOwnProperty('challengeName') && response.data.challengeName == 'NEW_PASSWORD_REQUIRED') {
                loginResData = {
                    "success": response.success,
                    "data": {
                        "challengeName": response.data.challengeName,
                        "session": response.data.Session,
                        "username": response.data.username
                    }
                }
            } else {
                loginResData = {
                    "success": response.success,
                    "data": {
                        "idToken": response.data.idToken,
                        "challengeName": response.data.challengeName,
                        "tokenType": response.data.tokenType,
                        "accessToken": response.data.accessToken,
                        "expiresIn": response.data.expiresIn,
                        "refreshToken": response.data.refreshToken,
                        "user_group": [],
                        "organizations": [],
                        'session':response.data.Session
                    }
                }
            }
        } else {
            loginResData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return loginResData;
}


export const currentUser = (currentUserData) => {
    let response = currentUserData.data;
    let currentUserResData = {};
    if (response) {
        if (response.success) {
            currentUserResData = {
                "success": response.success,
                "data": {
                    "id": response.data.id,
                    "user_details": {
                        "first_name": response.data.user_details.first_name,
                        "last_name": response.data.user_details.last_name,
                        "user_email": response.data.user_details.user_email,
                        "active": response.data.user_details.active,
                        "profile_img_url": response.data.user_details.profile_img_url,
                        "role_id": response.data.user_details.role_id,
                        "role_name": response.data.user_details.role_name.toLowerCase(),
                        "user_type": response.data.user_details.user_type,
                        "contact_number": response.data.user_details.contact_number,
                        "role_permission": response.data.user_details.role_permission,
                        "hotel_id": response.data.user_details.hotel_id,
                        "hotel_name": response.data.user_details.hotel_name,
                        "is_subscribed": response.data.user_details.is_subscribed,
                        "version": response.data.user_details.version,
                        "is_beneficiary_included": response.data.user_details.is_beneficiary_included,
                        "user_full_hotel_agency_details": response.data.user_details.user_full_hotel_agency_details,
                        "mfa": response.data.user_details.mfa,
                    }
                }
            }
        } else {
            currentUserResData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return currentUserResData;
}

export const forcePasswordChangeGet = (forcePasswordChangeData) => {
    return forcePasswordChangeData.data
}

export const forgotPasswordGet = (forgotPasswordData) => {
    let response = forgotPasswordData.data;
    let resData = {};
    if (response) {
        if (response.success) {
            resData = {
                "success": response.success,
                "message": response.message
            }
        } else {
            resData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return resData;
}

export const changePasswordGet = (changePasswordData) => {
    let response = changePasswordData.data;
    let changePassworResdData = {};
    if (response) {
        if (response.success) {
            changePassworResdData = {
                "success": response.success,
                "message": response.message
            }
        } else {
            changePassworResdData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return changePassworResdData
}
export const rolePermissionApi = (roledata) => {
    return roledata.data
}
export const getVersionListGet = (roledata) => {
    return roledata.data
}
export const userOrganisationListGet = (roledata) => {
    return roledata.data
}
export const verifyMfaOtpPost = (roledata) => {
    return roledata.data
}
export const resendOtpGet = (roledata) => {
    return roledata.data
}
export const verifyOtpPost = (roledata) => {
    return roledata.data
}
export const mfaAssignPut = (roledata) => {
    return roledata.data
}
export const associateSoftwareTokenPut = (roledata) => {
    return roledata.data
}
export const verifySoftwareTokenPut = (roledata) => {
    return roledata.data
}