import React from 'react';
import { get, post, put, del, patch } from '../../../Utility/Http';
import { userDetailsGet, userDeleteGet, resetPasswordApi, usersGet, awsVarifiedEmailFun, verifyMfaOtpPost, verifySoftwareTokenPut, mfaAssignPut } from '../Model/ProfileModel';

export const user_Details = async (id) => {
    const response = await get(`${process.env.EXTENDED_URL}user_personal_info/${id}`);
    return userDetailsGet(response);
};

export const userDelete = async (data) => {
    const response = await del(`${process.env.EXTENDED_URL}admin`, data);
    return userDeleteGet(response);
};

export const resetPassword = async (data) => {
    const response = await patch(`${process.env.EXTENDED_URL}admin/resetpassword`, data);
    return resetPasswordApi(response);
};

export const users = async (data, id) => {
    const response = await patch(`${process.env.EXTENDED_URL}admin/${id}`, data);
    return usersGet(response);
};

export const awsVarifiedEmail = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}currentuser/check_identity_status`, data);
    return awsVarifiedEmailFun(response);
};

export const verifyMfaOtp = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}users/mfa/verify`, data);
    return verifyMfaOtpPost(response);
};
export const verifySoftwareToken = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/verify_software_token`, data);
    return verifySoftwareTokenPut(response);
};
export const mfaAssign = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/usr_mfa_assign`, data);
    return mfaAssignPut(response);
};