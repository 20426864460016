import React from 'react';
import { toast } from 'react-toastify';
import renderHTML from 'react-render-html';
import moment from 'moment';
import * as Store from '../store'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
class Utility {
    //common toast notifications fn 
    static toastNotifications = (message, title, type, autoclose = true, companyLogo = "", component = <></>) => {
        let errorToastId = "";
        let warningToastId = "";
        let callbackToastId = "";
        //success toast message
        if (type === "success") {
            let _autoclose = 4000;
            if (autoclose === false) {
                _autoclose = autoclose
            }
            toast.success(
                <div className="tostify-inner-box">
                    <div className="iconbox">
                        <div className="alert_img">&nbsp;</div>
                    </div>
                    <div className="titleanddescriptionbox">
                        <h1>{title}</h1>
                        <div className="alert_box">
                            <div className="message_dec">{renderHTML(message)}</div>
                        </div>
                    </div>
                </div>, {
                position: 'bottom-left',
                autoClose: _autoclose,
                transition: toast.slide,
                hideProgressBar: false
            });
        } else if (type === "reminder") { // reminder toast notification
            let _autoclose = 4000;
            if (autoclose === false) {
                _autoclose = autoclose
            }
            toast.success(
                <div className='alert_box_reminder'>
                    <h1>{title}</h1>
                    <div className="alert_box">
                        <div className="alert_img_reminder">&nbsp;</div>
                        <div className="message_dec">{renderHTML(message)}</div>
                    </div>
                </div>, {
                position: 'bottom-left',
                autoClose: _autoclose,
                transition: toast.slide,
                hideProgressBar: false,
                closeOnClick: true
            });
        } else if (type === "info") { // info toast notification
            let _autoclose = 4000;
            if (autoclose === false) {
                _autoclose = autoclose
            }
            toast.success(
                <div>
                    <h1 className="info_title_text">{title}</h1>
                    <div className="alert_box">
                        <div className="alert_img_info"><img src={companyLogo} alt="No image" /></div>
                        <div className="message_dec">{renderHTML(message)}</div>
                    </div>
                </div>, {
                position: 'bottom-left',
                autoClose: _autoclose,
                transition: toast.slide,
                hideProgressBar: false
            });
        } else if (type === "error") { //error toast notification
            errorToastId = message;
            let _autoclose = 4000;
            if (autoclose === false) {
                _autoclose = autoclose
            }
            if (!toast.isActive(errorToastId)) {
                toast.error(
                    <div className="tostify-inner-box errorToastMsg">
                        <div className="titleanddescriptionbox">
                            <h1>{title}</h1>
                            <div className="alert_box">
                                <div className="message_dec">{renderHTML(message)}</div>
                            </div>
                        </div>
                        <div className="iconbox">
                            <div className="alert_img_error">&nbsp;</div>
                        </div>
                    </div>, {
                    position: 'bottom-left',
                    autoClose: _autoclose,
                    transition: toast.slide,
                    hideProgressBar: true,
                    toastId: errorToastId,
                    closeOnClick: true
                });
            }

        } else if (type === "warning") { // warning toast notification
            warningToastId = message;
            let _autoclose = 4000;
            if (autoclose === false) {
                _autoclose = autoclose
            }
            if (!toast.isActive(warningToastId)) {
                toast.warning(
                    <div className="tostify-inner-box">
                        <div className="iconbox">
                            <div className="alert_img_warning">&nbsp;</div>
                        </div>
                        <div className="titleanddescriptionbox">
                            <h1>{title}</h1>
                            <div className="alert_box">
                                <div className="message_dec">{renderHTML(message)}</div>
                            </div>
                        </div>
                    </div>, {
                    position: 'bottom-left',
                    autoClose: _autoclose,
                    transition: toast.slide,
                    hideProgressBar: false,
                    toastId: warningToastId,
                    closeOnClick: true
                });
            }
        } else if (type === "longError") { // long error toast notification
            errorToastId = message;
            if (!toast.isActive(errorToastId)) {
                toast.error(
                    <div className="warning_message_box_long">
                        <div className="iconbox">
                            <div className="alert_img_warning" >&nbsp;</div>
                        </div>
                        <h1 className="error_title">{title}</h1>
                        <div className="message_descriptipn error_title_dec">{renderHTML(message)}</div>
                    </div>, {
                    position: 'bottom-left',
                    autoClose: false,
                    transition: toast.slide,
                    hideProgressBar: true,
                    toastId: errorToastId,
                    closeOnClick: true
                });
            }

        } else if (type === "callback") {  // callback type toast notification
            callbackToastId = message;
            if (!toast.isActive(callbackToastId)) {
                toast(component, {
                    position: toast.POSITION.TOP_LEFT,
                    transition: toast.slide,
                    toastId: callbackToastId,
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    closeButton: false
                });
            }
        }
    }


    //toast message dismiss fn
    static toastNotificationsDismissAll = () => {
        toast.dismiss();
    }

    //name display format
    static displayNameFormat = (first_name = "", last_name = "") => {
        let concatname = first_name + " " + last_name
        return concatname
    }

    //role wise permission and userwise permission
    static roleWisePermission = (userId, visitorId, roleName) => {
        let permission = false;
        if (roleName == 'app_admin') {
            permission = true;
        } else {
            if (userId == visitorId) {
                permission = true;
            }
        }

        return permission;
    }

    //custom date picker function
    static customDatePicker = () => {
        const myCustomLocale = {
            // months list by order
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],

            // week days by order
            weekDays: [
                {
                    name: 'Monday',
                    short: 'M',
                },
                {
                    name: 'Tuesday',
                    short: 'T',
                },
                {
                    name: 'Wednesday',
                    short: 'W',
                },
                {
                    name: 'Thursday',
                    short: 'T',
                },
                {
                    name: 'Friday',
                    short: 'F',
                },
                {
                    name: 'Saturday',
                    short: 'S',
                    isWeekend: true,
                },
                {
                    name: 'Sunday', // used for accessibility 
                    short: 'S', // displayed at the top of days' rows
                    isWeekend: true, // is it a formal weekend or not?
                }
            ],

            // just play around with this number between 0 and 6
            weekStartingIndex: 0,

            // return a { year: number, month: number, day: number } object
            getToday(gregorainTodayObject) {
                return gregorainTodayObject;
            },

            // return a native JavaScript date here
            toNativeDate(date) {
                return new Date(date.year, date.month - 1, date.day - 1);
            },

            // return a number for date's month length
            getMonthLength(date) {
                return new Date(date.year, date.month, 0).getDate();
            },

            // return a transformed digit to your locale
            transformDigit(digit) {
                return digit;
            },

            // texts in the date picker
            nextMonth: 'Next Month',
            previousMonth: 'Previous Month',
            openMonthSelector: 'Open Month Selector',
            openYearSelector: 'Open Year Selector',
            closeMonthSelector: 'Close Month Selector',
            closeYearSelector: 'Close Year Selector',
            defaultPlaceholder: 'Select...',

            // for input range value
            from: 'from',
            to: 'to',


            // used for input value when multi dates are selected
            digitSeparator: ',',

            // if your provide -2 for example, year will be 2 digited
            yearLetterSkip: 0,

            // is your language rtl or ltr?
            isRtl: false,
        }
        return myCustomLocale;
    }

    //get current month first day and last day fn
    static currentMonthFirstDayLastDayFormat = () => {
        const currentDate = moment();
        const firstDayOfMonth = currentDate.clone().startOf('month');
        const lastDayOfMonth = currentDate.clone().endOf('month');
        const firstDayOfMonthString = firstDayOfMonth.format('YYYY-MM-DD');
        const lastDayOfMonthString = lastDayOfMonth.format('YYYY-MM-DD');
        let returnCurrentMonthFirstDayLastDay = {
            start_date: firstDayOfMonthString,
            end_date: lastDayOfMonthString,
        }
        return returnCurrentMonthFirstDayLastDay
    }

    //get role object from event key name 
    static getRoleObjectByEventKey = (eventKey) => {
        let roleJsonData = Store.default.getState().LoginReducer.userCredentials.user_details.role_permission;
        let foundObject = JSON.parse(roleJsonData).find(item => item.eventKey === eventKey);
        return foundObject || {};
    }

    //get role permissions object from event key and sub-event key
    static getRoleObjectBySubEventKey = async (passEventKey, passVariable) => {
        let roleJsonData = await Store.default.getState().LoginReducer.userCredentials.user_details.role_permission;
        let objectsWithSubEventKey = JSON.parse(roleJsonData).filter(item => item.eventKey == passEventKey);
        let matchedObjects = [];
        if (objectsWithSubEventKey && objectsWithSubEventKey.length > 0) {
            for (let i = 0; i < objectsWithSubEventKey.length; i++) {
                let obj = objectsWithSubEventKey[i];
                if (obj.subeventKey) {
                    for (let j = 0; j < obj.subeventKey.length; j++) {
                        let subEventMatch = obj.subeventKey[j];
                        if (subEventMatch.eventKey === passVariable) {
                            if (subEventMatch.rules !== "") {
                                matchedObjects = []
                                matchedObjects.push(subEventMatch);
                            }
                        }
                    }
                }
            }
        }
        return matchedObjects;
    }

    //capitalize first letter of each word
    static capitalizeFirstLetterOfEachWord(str) {
        // Split the string into words
        const words = str.split(/\s+/);
        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => {
            if (word.length > 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
                return word; // If the word is empty, keep it as is
            }
        });
        // Join the words back into a string with spaces
        return capitalizedWords.join(' ');
    }

    // Capitalize only the first letter of a string
    static capitalizeOnlyFirstLetter = (str) => {
        if (!str || typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    //get currency symbol
    static getCurrencySymbol(currencyCode) {
        function identifyCurrency(value) {
            // Regular expression to match three-letter currency codes
            const currencyCodeRegex = /^[A-Z]{3}$/;

            // Check if the value matches the currency code regex
            if (currencyCodeRegex.test(value)) {
                return false;
            } else {
                return true;
            }
        }

        try {
            const locale = localStorage.getItem('i18nextLng') || 'en-US'; // Default to 'en-US' if locale is not available
            const formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currencyCode,
            });
            const parts = formatter.formatToParts(1);
            const symbol = parts.find(part => part.type === 'currency')?.value;
            let symbolOrNot = identifyCurrency(symbol);
            if (!symbolOrNot) {
                switch (currencyCode) {
                    case 'AED':
                        return 'د.إ'; // UAE Dirham symbol
                    case 'BDT':
                        return '৳'; // Bangladeshi Taka symbol
                    default:
                        return symbol;
                }
            }

            return symbol;
        } catch (error) {
            return '';
        }
    }

    //get two decimal number fn
    static getTwoDecimalNumber(value) {
        let floatValue = parseFloat(value);
        if (isNaN(floatValue)) {
            floatValue = 0
        }
        return floatValue.toFixed(2)
    }

    //upi validation function
    static validateUPI(upiId) {
        // Regular expression pattern for UPI ID validation
        const upiRegex = /^[\w.-]+@[\w.-]+$/;

        // Check if the provided UPI ID matches the pattern
        return upiRegex.test(upiId);
    }

    //image type file accect fn
    static acceptFileType = () => {
        let fileType = ".jpg, .jpeg, .png"
        return fileType;
    }

    //check file type according to incoming file type
    static checkFileType(file, allowedTypes) {
        const allowedTypesArray = allowedTypes.split(',').map(type => type.trim());
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const arr = new Uint8Array(reader.result.slice(0, 4));
                const header = Array.from(arr, byte => byte.toString(16).padStart(2, '0')).join('');
                const magicNumbers = {
                    'jpg': 'ffd8ffe0',
                    'jpeg': 'ffd8ffe1',
                    'png': ['89504e47', '0000001c'],
                    'xls': ['d0cf11e0', '496e6475'],  // Legacy Excel files
                    'xlsx': ['504b0304', '496e6475'],  // Open XML Excel files (also matches ZIP files)
                    'pdf': '25504446',
                    'csv': [null, '22426f6f', '534c2e20'],  // CSV files don't have a specific magic number
                    'doc': 'd0cf11e0',  // Legacy Word files
                    'docx': '504b0304'
                };

                for (let type of allowedTypesArray) {
                    const typeWithoutDot = type.replace('.', '');
                    const magicNumber = magicNumbers[typeWithoutDot];
                    if (Array.isArray(magicNumber)) {
                        if (magicNumber.includes(header)) {
                            resolve({ isValid: true, fileType: type });
                            return;
                        }
                    } else {
                        if (header.startsWith(magicNumber)) {
                            resolve({ isValid: true, fileType: type });
                            return;
                        }
                    }
                }

                // If file type doesn't match any of the allowed types
                const detectedType = file.type.split('/')[1];
                resolve({ isValid: false, fileType: detectedType });
            };

            reader.onerror = () => {
                reject("Error reading file");
            };

            // Read the file
            reader.readAsArrayBuffer(file);
        });
    }

    //return start date and end date according to incoming type
    static returnStartEndDate(type) {
        let startDate = new Date(moment().format('YYYY-MM-DD'));
        let endDate = "";
        if (type === 'day') {
            endDate = new Date(moment().format('YYYY-MM-DD'));
        }
        if (type === 'month') {
            endDate = new Date(moment(startDate, "YYYY-MM-DD").add(1, 'month').format('YYYY-MM-DD'));
        }
        if (type === 'year') {
            endDate = new Date(moment(startDate, "YYYY-MM-DD").add(1, 'year').format('YYYY-MM-DD'));
        }
        if (type === 'day-1') {
            startDate = new Date(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'));
            endDate = new Date(moment().format('YYYY-MM-DD'));
        }
        if (type === 'month-1') {
            endDate = startDate;
            startDate = new Date(moment(startDate, "YYYY-MM-DD").subtract(1, 'month').format('YYYY-MM-DD'));
        }
        if (type === 'year-1') {
            endDate = startDate;
            startDate = new Date(moment(startDate, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD'));
        }
        return { startDate: startDate, endDate: endDate };
    }

    //remove 0 value from given input
    static hasNonZeroValues(input) {
        const numbers = input.split(',').map(Number);
        return numbers.some(num => num !== 0);
    }

    //get am/pm from given date time    
    static get12HourFormat(dateTimeString) {
        const date = new Date(dateTimeString);
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} at ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
        return formattedDate;
    }

    //get booking date ui 
    static bookingDateUi = (classNameCss, actualDate, inOutDate, type) => {
        let renderDate = actualDate;
        let tooltipText = null;

        if (inOutDate !== null) {
            const isDifferentDate = !moment(actualDate).isSame(inOutDate, 'day');
            tooltipText = isDifferentDate ? `Actual ${type} : \n ${moment(inOutDate.toString()).format('DD-MM-YYYY')}` : null;
        }
        let colors = '#5f646d';
        colors = inOutDate !== null && tooltipText ? 'red' : '#5f646d';

        return (
            <div className={classNameCss}>
                {inOutDate === null ? (
                    <div style={{ color: colors }}>{moment(renderDate.toString()).format('DD-MM-YYYY')}</div>
                ) : (
                    tooltipText ? (
                        <OverlayTrigger overlay={<Tooltip>{tooltipText.split('\n').map((line, ind) => (
                            <div key={ind}>{line}</div>
                        ))}</Tooltip>}>
                            <span style={{ color: colors }}>{moment(renderDate.toString()).format('DD-MM-YYYY')}</span>
                        </OverlayTrigger>
                    ) : (
                        <span style={{ color: colors }}>{moment(renderDate.toString()).format('DD-MM-YYYY')}</span>
                    )
                )}
            </div>
        );
    };

    //User wise permission
    static userWisePermission = (userRoleId, visitorId, userType) => {
        let permission = false;
        if (userType == 'admin' || userType == 'app_admin') {
            permission = true;
        } else {
            if (userRoleId == visitorId) {
                permission = false;
            } else {
                permission = true;
            }
        }
        return permission;
    }

}


export default Utility;